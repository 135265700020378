/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccidentReportForm } from '../forms/accidentReportForm';
import { UseFormReturn } from 'react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import V3_Button from 'components_v3/ui/Button';
import useKakaoMap_V3 from 'hooks_v3/useKakaoMap';
import { Loader2, LocateFixed, X } from 'lucide-react';
import V3_Confirm from 'components_v3/dialog/Confirm';

import Lottie from 'lottie-react';
import loading_iOS_style_1 from '../../../../../../lottie/loading_iOS_style_1.json';
import { AccidentReport, RepairShop } from '@carsayo/types';
import { getDistance } from 'geolib';
import { useMutation } from '@tanstack/react-query';
import { getMemberAccidentReport } from 'apis/accidentReport';
import { io, Socket } from 'socket.io-client';
import useMemberStore from 'store/useMemberStore';
import is영업중 from 'pages/v3/accidentReport/utils/checkWorkingMode';
import { useDataStore } from 'store/useDataStore';
import useOnResumeStore from 'store/useOnResumeStore';

/** 20km 이내 공업사만 검색 */
const DISTANCE_THRESHOLD = 20000;

export default function AccidentReportSearchTab({
  currentTab,
  setCurrentTab,
  form,
  totalRepairshopList,
  setSelectedRepairShop,
}: {
  currentTab: number;
  setCurrentTab: (tab: number) => void;
  form: UseFormReturn<AccidentReportForm>;
  totalRepairshopList: RepairShop[];
  setSelectedRepairShop: (
    shop: RepairShop & { accidentReportId: string; distance: number },
  ) => void;
}) {
  const { setOnResume } = useOnResumeStore();

  const { holiday } = useDataStore();

  const { loginMember } = useMemberStore();

  const mapRef = useRef<HTMLDivElement>(null);
  const kakaoMap = useKakaoMap_V3({
    containerRef: mapRef,
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  /** 필터침 */
  const filteredRepairShopList = useMemo(() => {
    return totalRepairshopList.filter((el) => {
      /** 견인 필요 주문에 대하여 견인 불가능한 공업사는 필터링 */
      if (
        form.getValues('option_traction') === true &&
        el.towing_available === false
      ) {
        return false;
      }

      const isWorking = is영업중(el, holiday);
      if (isWorking === false) {
        return false;
      }

      return (
        el.location.latitude &&
        el.location.longitude &&
        el.working_status === 'open'
      );
    });
  }, [totalRepairshopList, holiday]);

  /** 거리 계산 -> 특정 거리 이상 필터링 -> 이후 거리순(가까운것부터) 정렬 */
  const sortedRepairShopList = useMemo(() => {
    if (!kakaoMap.currentPosition) return [];

    const dataWithDistance = filteredRepairShopList.map((el) => {
      return {
        ...el,
        distance: getDistance(kakaoMap.currentPosition!, {
          latitude: el.location.latitude!,
          longitude: el.location.longitude!,
        }),
      };
    });

    /** 거리 제한 처리 */
    const filteredData = dataWithDistance.filter((el) => {
      return el.distance <= DISTANCE_THRESHOLD;
    });

    return filteredData.sort((a, b) => {
      const distanceA = a.distance;
      const distanceB = b.distance;
      return distanceA - distanceB;
    });
  }, [filteredRepairShopList, kakaoMap.currentPosition]);

  useEffect(() => {
    if (sortedRepairShopList && !kakaoMap.isLoading) {
      kakaoMap.setMarker(
        sortedRepairShopList.map((el) => {
          return {
            title: el.name,
            position: {
              lat: el.location.latitude!,
              lng: el.location.longitude!,
            },
          };
        }),
      );
    }
  }, [sortedRepairShopList, kakaoMap.isLoading]);

  useEffect(() => {
    if (kakaoMap.currentPosition) {
      form.setValue('latitude', kakaoMap.currentPosition.lat);
      form.setValue('longitude', kakaoMap.currentPosition.lng);
    }
    if (isFailed) {
      setIsFailed(false);
    }
  }, [kakaoMap.currentPosition]);

  const [socket, setSocket] = useState<Socket | null>(null);
  /** 소켓 송수신간 저장되는 데이터 */
  const [socketData, setSocketData] = useState<{
    accidentReportId: string | null;
  } | null>(null);

  const { mutate: checkAfterGetReportCreated } = useMutation({
    mutationFn: async () => {
      const accidentReport = await getMemberAccidentReport({
        id: socketData?.accidentReportId ?? '',
      });
      return accidentReport;
    },
    onSuccess: (accidentReport: AccidentReport) => {
      if (accidentReport.state === 'submitted') {
        return;
      }
      if (accidentReport.state !== 'accepted' || !accidentReport.repairShop) {
        setIsFailed(true);
      } else {
        form.setValue('partner_repairshop_id', accidentReport.repairShop.id);
        setSelectedRepairShop({
          ...accidentReport.repairShop,
          accidentReportId: accidentReport.id,
          distance: getDistance(kakaoMap.currentPosition!, {
            latitude: accidentReport.repairShop.location.latitude!,
            longitude: accidentReport.repairShop.location.longitude!,
          }),
        });
        setCurrentTab(currentTab + 1);
      }
    },
    onError: () => {
      setIsFailed(true);
    },
  });

  /** 가까운 1급 공업사 검색 중... */
  const search = () => {
    setIsPopupOpen(true);

    if (!kakaoMap.currentPosition) return;
    if (!loginMember) return;

    const requestData = form.getValues();

    // 최대 20개까지만 요청
    const targetRepairshopList = sortedRepairShopList.slice(0, 20).map((el) => {
      return el.id;
    });

    if (targetRepairshopList.length === 0) {
      setIsFailed(true);
      return;
    }

    const targetUrl = new URL(process.env.REACT_APP_API_URL as string);
    targetUrl.pathname = '/accidentReport/socket/search';
    targetUrl.protocol = targetUrl.protocol === 'https:' ? 'wss:' : 'ws:';

    /** iOS는 네이티브단에서 소켓 연결을 하고, 리액트단에서 응답을 처리합니다. */
    if (window?.webkit?.messageHandlers?.iosSendAccidentReportSocket) {
      window.webkit.messageHandlers.iosSendAccidentReportSocket.postMessage({
        targetUrl: targetUrl.hostname,
        targetPath: targetUrl.pathname,
        userId: loginMember.id,
        data: JSON.stringify(requestData),
        targetRepairshopList: JSON.stringify(targetRepairshopList),
      });

      window.native.socketOn = (key: string, data: any) => {
        if (key === 'connect') {
          if (connectionTimeout) {
            clearTimeout(connectionTimeout);
            connectionTimeout = null;
          }
        }

        if (key === 'disconnect') {
          setSocketData(null);
        }

        if (key === 'reportCreated') {
          setSocketData({
            accidentReportId: data.reportId,
          });
        }

        if (key === 'reportAccepted') {
          checkAfterGetReportCreated();
        }
      };

      return;
    }

    let connectionTimeout: ReturnType<typeof setTimeout> | null = null;
    const socket = io(targetUrl.href, {
      query: {
        userId: loginMember.id,
        data: JSON.stringify(requestData),
        targetRepairshopList: JSON.stringify(targetRepairshopList),
      },
      transports: ['websocket'],
    });
    setSocket(socket);

    console.info('Socket connection started');

    socket.on('connect', () => {
      if (connectionTimeout) {
        clearTimeout(connectionTimeout);
        connectionTimeout = null;
      }
    });
    socket.on('disconnect', () => {
      setSocket(null);
    });
    socket.on('reportCreated', (data: { reportId: string }) => {
      setSocketData({
        accidentReportId: data.reportId,
      });
    });
    socket.on('reportAccepted', (data: { reportId: string }) => {
      checkAfterGetReportCreated();
    });

    // 10초동안 연결 실패 시 에러 처리
    connectionTimeout = setTimeout(() => {
      setIsFailed(true);
    }, 10000);
  };

  const cancelSearch = () => {
    if (window?.webkit?.messageHandlers?.iosDisconnectAccidentReportSocket) {
      window.webkit.messageHandlers.iosDisconnectAccidentReportSocket.postMessage(
        '',
      );
      setSocketData(null);
      return;
    }

    if (socket) {
      socket.close();
      setSocket(null);
    }
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (kakaoMap.currentPosition) {
      form.setValue(
        'info_address',
        kakaoMap.currentPosition?.address?.road_address?.address_name ??
          kakaoMap.currentPosition?.address?.address?.address_name ??
          '',
      );
    }
  }, [kakaoMap.currentPosition?.address]);

  useEffect(() => {
    if (socket === null) {
      setSocketData(null);
    }
  }, [socket]);

  useEffect(() => {
    return () => {
      window.native.socketOn = undefined;
    };
  }, []);

  useEffect(() => {
    setOnResume(() => {
      if (socketData?.accidentReportId) {
        checkAfterGetReportCreated();
      }
    });
  }, [socketData]);

  return (
    <div className='h-full w-full flex flex-col justify-between relative'>
      <div className='relative h-full flex flex-auto items-center justify-center w-full border-t border-[#E5E5EC]'>
        {kakaoMap.isLoading && (
          <div className='absolute top-0 left-0 w-full h-full z-10 bg-black/40 flex items-center justify-center'>
            <Loader2 className='w-14 h-14 text-white animate-spin' />
          </div>
        )}
        <div
          className='kakaoMapContainer w-full h-full pointer-events-auto touch-auto'
          ref={mapRef}
        ></div>
        {!kakaoMap.isLoading && (
          <div
            onClick={() => kakaoMap.moveToLocationPosition()}
            className='absolute right-4 bottom-4 rounded-full z-10 bg-white p-2 cursor-pointer'
          >
            {kakaoMap.geoState === 'loading' ? (
              <Loader2 className='animate-spin' />
            ) : (
              <LocateFixed />
            )}
          </div>
        )}
        {isFailed && (
          <div className='absolute gap-4 flex text-white items-center px-5 py-[9px] justify-center z-10 rounded-[10px] bg-[rgba(0,0,0,0.70)] backdrop-blur-[10px] bottom-5'>
            <div className='text-[#FFF] font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              현재 위치 기반으로 공업사를 찾을 수 없습니다.
            </div>
            <X onClick={() => setIsFailed(false)} />
          </div>
        )}
      </div>
      <div className='relative w-full bg-white px-5 pt-6 pb-2.5 gap-[20px] flex flex-col justify-between items-start min-h-[190px] shadow-[0px_0px_15px_#1111111A] z-10 '>
        <div>
          <div className='text-[#222] text-[18px] font-semibold leading-[28.8px] tracking-[-0.36px]'>
            {kakaoMap.currentPosition?.address?.road_address?.address_name ??
              kakaoMap.currentPosition?.address?.address?.address_name}
          </div>
          <div className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px] mt-1'>
            {kakaoMap.currentPosition?.address?.road_address?.building_name}
          </div>
        </div>

        <div className='flex w-full justify-between items-center gap-[7px]'>
          <V3_Button
            className='text-[16px] leading-[16px] font-[500]'
            onClick={() => {
              setCurrentTab(currentTab - 1);
            }}
          >
            이전
          </V3_Button>
          <V3_Button
            className='text-[16px] leading-[16px] font-[500]'
            disabled={!kakaoMap.currentPosition || kakaoMap.isLoading}
            theme='primary'
            onClick={search}
          >
            공업사 검색
          </V3_Button>
        </div>
      </div>

      <V3_Confirm
        isAlert={true}
        onClose={() => {
          cancelSearch();
          setIsFailed(false);
        }}
        onConfirm={() => {
          setIsPopupOpen(false);
        }}
        isOpen={isPopupOpen}
        setIsOpen={setIsPopupOpen}
        disableBackdropClose={true}
        agreeText={'취소'}
        title={''}
        className='!pb-[50px] !pt-[24px]'
      >
        {isFailed === false ? (
          <div className='flex flex-col items-center justify-center gap-5'>
            <Lottie
              animationData={loading_iOS_style_1}
              loop={true}
              className='w-[58px] h-[58px]'
            />
            <div className='flex flex-col items-center justify-center gap-1.5'>
              <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
                {!socket?.connected
                  ? '공업사 연결 중'
                  : '가까운 1급 공업사 검색 중'}
              </div>
              <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
                접수 항목에 맞춰 자동으로 배정되며
                <br />
                진행 상태는 마이페이지에서 확인할 수 있습니다.
              </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-col items-center justify-center gap-5'>
            <div className='w-[52.2px] h-[52.2px] flex justify-center items-center rounded-full bg-[#F9D7D7]'>
              <X width={23.2} height={23.2} color='#DC0000' strokeWidth={3} />
            </div>
            <div className='flex flex-col items-center justify-center gap-1.5'>
              <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
                공업사 검색 실패
              </div>
              <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
                현재 위치 기반으로
                <br />
                공업사를 찾을 수 없습니다.
              </div>
            </div>
          </div>
        )}
      </V3_Confirm>
    </div>
  );
}
