import V3_Button from 'components_v3/ui/Button';
import { ChevronDown, ChevronRight, ChevronUp } from 'lucide-react';
import MainFooter from 'pages/v2/mainPage/components/MainFooter';
import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

// 메인 프로필카드 섹션
const MainCard = ({
  dealerType,
  isData,
}: {
  dealerType: string;
  isData: string;
}) => {
  const [isEllipsis, setIsEllipsis] = useState(true);
  return (
    <section className='p-5 pb-0 mb-[50px]'>
      <div className='px-4 py-5 rounded-[16px] bg-white flex flex-col justify-between items-start gap-6 shadow-[0px_6px_20px_#0000000D]'>
        <div className='w-full'>
          <div className='w-full flex justify-start items-center gap-4'>
            <div className='w-[56px] h-[56px] rounded-full flex justify-center items-center overflow-hidden'>
              <img
                src='/assets/images/png/hospital-ex-img01.png'
                alt='예시 이미지'
                className='w-full h-full object-cover'
              />
            </div>
            <div className='flex flex-col justify-start items-start gap-2'>
              <strong className='text-[16px] leading-[16px] font-[600]'>
                홍길동 님
              </strong>
              <ul className='flex justify-start items-center gap-1'>
                <li className='text-[14px] leading-[14px] font-[500]'>
                  {dealerType === 'newcar'
                    ? '신차'
                    : dealerType === 'usedcar'
                      ? '중고차'
                      : '리스/장기렌트'}{' '}
                  매니저
                </li>
                <li className='flex justify-center items-center'>
                  <span className='w-[3px] h-[3px] rounded-full bg-[#D5DADF]'></span>
                </li>

                {/* 승인 완료 */}
                <li className='flex justify-start items-center gap-0.5'>
                  <img
                    src='/assets/images/png/check.png'
                    alt='체크이미지'
                    className='w-4 object-contain'
                  />
                  <span className='text-[13px] leading-[13px] font-[500]'>
                    승인 완료
                  </span>
                </li>

                {/* 승인 대기 */}
                {/* <li className='flex justify-start items-center gap-0.5'>
                  <img
                    src='/assets/images/png/wait.png'
                    alt='대기이미지'
                    className='w-4 object-contain'
                  />
                  <span className='text-[13px] leading-[13px] font-[500]'>
                    승인 대기
                  </span>
                </li> */}

                {/* 승인 거부 */}
                {/* <li className='flex justify-start items-center gap-0.5'>
                  <img
                    src='/assets/images/png/cancel.png'
                    alt='거부 이미지'
                    className='w-4 object-contain'
                  />
                  <span className='text-[13px] leading-[13px] font-[500]'>
                    승인 거부
                  </span>
                </li> */}
              </ul>
            </div>
          </div>
          <div className='mt-4'>
            <p
              className={cn(
                isEllipsis
                  ? 'line-clamp-3 whitespace-pre-line'
                  : 'whitespace-pre-line',
              )}
            >
              안녕하세요. 항상 고객님과의 약속이 최우선입니다. 계약부터 출고까지
              초심 그대로 유지하는 모습 보여드리겠습니다. 언제든지 불러 주세요.
              더 자세한 내용이 궁금하다면 상담 요청을 해주세요.안녕하세요. 항상
              고객님과의 약속이 최우선입니다. 계약부터 출고까지 초심 그대로
              유지하는 모습 보여드리겠습니다. 언제든지 불러 주세요. 더 자세한
              내용이 궁금하다면 상담 요청을 해주세요.
            </p>
            <div className='flex justify-end items-center gap-[3px] mt-3'>
              {isEllipsis ? (
                <button
                  onClick={() => setIsEllipsis(false)}
                  className='flex justify-center items-center text-[14px] leading-[14px] font-[600] text-[#494AF1]'
                >
                  더보기
                  <ChevronDown
                    width={16}
                    height={16}
                    color='#494AF1'
                    strokeWidth={1.8}
                  />
                </button>
              ) : (
                <button
                  onClick={() => setIsEllipsis(true)}
                  className='flex justify-center items-center text-[14px] leading-[14px] font-[600] text-[#494AF1]'
                >
                  접기
                  <ChevronUp
                    width={16}
                    height={16}
                    color='#494AF1'
                    strokeWidth={1.8}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className='w-full p-[15px] pr-2.5 bg-[#F3F6F9] rounded-[8px] flex justify-between items-center'>
          <span className='text-[14px] leading-[19.6px] font-[500] inline-block pl-[1px]'>
            미입금 수수료
          </span>
          <div className='flex justify-center items-center'>
            <span className='text-[14px] leading-[19.6px] font-[500]'>
              {isData === 'true' ? '120,000' : '0'} 원
            </span>
            <ChevronRight
              width={20}
              height={20}
              color='#999999'
              strokeWidth={1.5}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

// 신차 구매요청 섹션
const NewCarRequest = ({
  isData,
  dealerType,
}: {
  isData: string;
  dealerType: string;
}) => {
  return (
    <section className='mb-[30px]'>
      <div className='mb-5 flex justify-between items-center gap-4 px-5'>
        <div className='flex justify-start items-center gap-1.5'>
          <span className='text-[22px] leading-[33px] font-[600]'>
            신차 구매 요청
          </span>
          <span className='text-[15px] leading-[22.5px] text-white rounded-[100px] bg-[#222] px-[9px] py-0.5'>
            {isData === 'true' ? 9 : 0}
          </span>
        </div>
        <ChevronRight width={30} height={30} color='#222' strokeWidth={1.5} />
      </div>

      {isData === 'true' ? (
        // 데이터 있을때
        <div className='w-full overflow-x-auto pl-5 scroll-hidden'>
          <ul className='inline-flex justify-start items-stretch gap-2.5 mr-5 pb-5'>
            <li className='w-[calc(100dvw-40px)]'>
              <RequestCard state={'request'} />
            </li>
            <li className='w-[calc(100dvw-40px)]'>
              <RequestCard state={'request'} />
            </li>
          </ul>
        </div>
      ) : (
        // 데이터 없을때
        <div className='px-5'>
          <div className='w-full min-h-[240px] flex flex-col justify-center items-center bg-[#E5EAEE] rounded-[16px] gap-6'>
            <div className='w-[80px] h-[80px] rounded-full bg-[#F3F6F9] flex justify-center items-center p-[15px]'>
              <img
                src='/assets/images/png/noNewCar.png'
                alt='구매요청 없음 아이콘'
              />
            </div>
            <span className='text-[18px] leading-[27px] font-[600] text-[#A1A7AC]'>
              구매 요청 내역이 없어요
            </span>
          </div>
        </div>
      )}
    </section>
  );
};

// 중고차 구매요청 섹션
const UsedCarRequest = ({
  isData,
  dealerType,
}: {
  isData: string;
  dealerType: string;
}) => {
  return (
    <section className='mb-[30px]'>
      <div className='mb-5 flex justify-between items-center gap-4 px-5'>
        <div className='flex justify-start items-center gap-1.5'>
          <span className='text-[22px] leading-[33px] font-[600]'>
            내 차 팔기 요청
          </span>
          <span className='text-[15px] leading-[22.5px] text-white rounded-[100px] bg-[#222] px-[9px] py-0.5'>
            {isData === 'true' ? 2 : 0}
          </span>
        </div>
        <ChevronRight width={30} height={30} color='#222' strokeWidth={1.5} />
      </div>

      {isData === 'true' ? (
        // 데이터 있을때
        <div className='w-full overflow-x-auto pl-5 scroll-hidden'>
          <ul className='inline-flex justify-start items-stretch gap-2.5 mr-5 pb-5'>
            <li className='w-[calc(100dvw-40px)]'>
              <RequestCard state={'request'} dealerType={dealerType} />
            </li>
            <li className='w-[calc(100dvw-40px)]'>
              <RequestCard state={'request'} dealerType={dealerType} />
            </li>
          </ul>
        </div>
      ) : (
        // 데이터 없을때
        <div className='px-5'>
          <div className='w-full min-h-[240px] flex flex-col justify-center items-center bg-[#E5EAEE] rounded-[16px] gap-6'>
            <div className='w-[80px] h-[80px] rounded-full bg-[#F3F6F9] flex justify-center items-center p-[15px]'>
              <img
                src='/assets/images/png/noNewCar.png'
                alt='구매요청 없음 아이콘'
              />
            </div>
            <span className='text-[18px] leading-[27px] font-[600] text-[#A1A7AC]'>
              판매 요청 내역이 없어요
            </span>
          </div>
        </div>
      )}
    </section>
  );
};

// 진행 중인 견적 섹션
const ProgressRequest = ({
  isData,
  dealerType,
}: {
  isData: string;
  dealerType: string;
}) => {
  return (
    <section className='mb-[50px]'>
      <div className='mb-5 flex justify-between items-center gap-4 px-5'>
        <div className='flex justify-start items-center gap-1.5'>
          <span className='text-[22px] leading-[33px] font-[600]'>
            진행 중인 견적
          </span>
          <span className='text-[15px] leading-[22.5px] text-white rounded-[100px] bg-[#222] px-[9px] py-0.5'>
            {isData === 'true' ? 11 : 0}
          </span>
        </div>
        <ChevronRight width={30} height={30} color='#222' strokeWidth={1.5} />
      </div>
      {isData === 'true' ? (
        <>
          {/* 데이터 있을때 */}
          <ul className='grid gird-cols-1 gap-5 px-5'>
            <li>
              <RequestCard
                state={'progress'}
                status={'bidding'}
                dealerType={dealerType}
              />
            </li>
            <li>
              <RequestCard
                state={'progress'}
                status={'bidding'}
                dealerType={dealerType}
              />
            </li>
            <li>
              <RequestCard
                state={'progress'}
                status={'success'}
                dealerType={dealerType}
              />
            </li>
            <li>
              <RequestCard
                state={'progress'}
                status={'success'}
                dealerType={dealerType}
              />
            </li>
            <li>
              <RequestCard
                state={'closed'}
                status={'failed'}
                dealerType={dealerType}
              />
            </li>
          </ul>
          <div className='px-5 mt-[30px]'>
            <V3_Button
              theme='gray'
              className='!h-[50px] !text-[15px] !leading-[22.5px] !bg-[#E5EAEE] !font-[600]'
            >
              11건 전체보기
            </V3_Button>
          </div>
        </>
      ) : (
        // 데이터 업을때
        <div className='px-5'>
          <div className='w-full min-h-[240px] flex flex-col justify-center items-center bg-[#E5EAEE] rounded-[16px] gap-6'>
            <div className='w-[80px] h-[80px] rounded-full bg-[#F3F6F9] flex justify-center items-center p-[15px]'>
              <img
                src='/assets/images/png/noRequest.png'
                alt='견적 없음 아이콘'
              />
            </div>
            <span className='text-[18px] leading-[27px] font-[600] text-[#A1A7AC]'>
              진행 중인 견적이 없어요
            </span>
          </div>
        </div>
      )}
    </section>
  );
};

// 구매요청 카드
const RequestCard = ({
  state,
  status,
  dealerType,
}: {
  state: string;
  status?: string;
  dealerType?: string;
}) => {
  return (
    <div
      className={cn(
        'w-full p-4 pt-[15px] bg-white rounded-[16px] relative shadow-[0px_6px_20px_#0000000D]',
        status === 'failed' && 'pb-5',
      )}
    >
      {state === 'request' && (
        <span className='w-2 h-2 rounded-full bg-[#7273F9] absolute top-3 right-3'></span>
      )}
      <div className='flex justify-between items-center gap-2.5 border-b border-[#E5EAEE] pb-[15px]'>
        <div className='flex justify-start items-center gap-2.5 tiny:gap-1.5'>
          <strong
            className={cn(
              'text-[16px] leading-[16px] font-[600] shrink-0',
              state === 'closed' && 'text-[#999999]',
            )}
          >
            {state === 'request'
              ? '견적 요청'
              : state === 'progress'
                ? '진행 중'
                : '입찰 종료'}
          </strong>
          {status === 'success' ? (
            <div className='text-[14px] leading-[14px] font-[600] text-[#005CE6] flex justify-center items-center w-[36px] h-[28px] bg-[#E3EEFF] rounded-[6px]'>
              낙찰
            </div>
          ) : status === 'failed' ? (
            <div className='text-[14px] leading-[14px] font-[600] text-[#999999] flex justify-center items-center w-[36px] h-[28px] bg-[#F1F4F6] rounded-[6px]'>
              유찰
            </div>
          ) : (
            <div className='flex justify-start pl-1 items-center gap-1 min-w-[121px] h-[28px] v:min-w-[108px] rounded-[6px] bg-[#FFF0F0]'>
              <img
                src='/assets/images/png/clock.png'
                alt='시계 아이콘'
                className='w-4 object-contain'
              />
              <span className='text-[14px] leading-[14px] font-[600] text-[#FF4747] tiny:text-[12px] tiny:leading-[12px]'>
                29일 02:00:00
              </span>
            </div>
          )}
        </div>
        {state !== 'request' && (
          <span className='text-[13px] leading-[19.5px] font-[500] text-[#999] tiny:text-[12px] tiny:leading-[18px]'>
            24.12.24 20:00
          </span>
        )}
      </div>
      <div className='py-4 border-b border-[#E5EAEE] flex justify-start items-center gap-4 tiny:gap-2.5'>
        {dealerType === 'usedcar' ? (
          <div className='w-[40%] aspect-[120/90] max-w-[160px] min-w-[110px] bg-[#F3F6F9] flex justify-center items-center rounded-[10px] overflow-hidden'>
            <img
              src='/assets/images/png/ex-used-car.png'
              alt='예시 차량 사진'
              className='w-full h-full object-cover'
            />
          </div>
        ) : (
          <div className='w-[40%] aspect-[120/90] max-w-[160px] min-w-[110px] bg-[#F3F6F9] flex justify-center items-center py-[18px] px-[15px] rounded-[10px]'>
            <img src='/assets/images/png/ex-car.png' alt='예시 차량 사진' />
          </div>
        )}

        <div className='w-full flex flex-col justify-start items-start gap-1'>
          <span className='inline-block mb-0.5 text-[14px] leading-[14px] font-[500] text-[#555555] tiny:text-[13px] tiny:leading-[13px]'>
            {dealerType === 'usedcar' ? '내 차 팔기' : '일시불'}
          </span>
          <strong className='text-[14px] leading-[22.4px] font-[600] break-keep tiny:text-[13px] tiny:leading-[20.8px]'>
            기아 GLE-클래스 24년식
          </strong>
          {dealerType === 'usedcar' ? (
            <p className='text-[13px] leading-[20.8px] font-[500] text-[#555555] break-keep'>
              33부 0777
            </p>
          ) : (
            <p className='w-[calc(100%-10px)] text-[13px] leading-[20.8px] font-[500] text-[#555555] truncate tiny:text-[12px] tiny:leading-[19.2px]'>
              가솔린 2.5 터보 2WD 기본형
            </p>
          )}
        </div>
      </div>
      <div className='pt-4 flex flex-col justify-start items-start gap-6'>
        <ul className='flex flex-col justify-start items-start gap-[14px] text-[15px] leading-[15px]'>
          <li className='flex justify-start items-center'>
            <span className='min-w-[75px]'>요청일</span>
            <strong className='font-[500]'>2025.01.06</strong>
          </li>
          {dealerType === 'usedcar' ? (
            <>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>연식</span>
                <strong className='font-[500]'>2020년형</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>주행거리</span>
                <strong className='font-[500]'>171,100km</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>판매지역</span>
                <strong className='font-[500]'>서울시 중랑구</strong>
              </li>
              {(status === 'bidding' || status === 'failed') && (
                <li className='flex justify-start items-center'>
                  <span className='min-w-[75px]'>입찰가</span>
                  <strong className='font-[500]'>1,200만원</strong>
                </li>
              )}
              {status === 'success' && (
                <li className='flex justify-start items-center'>
                  <span className='min-w-[75px]'>낙찰가</span>
                  <strong className='font-[500]'>1,200만원</strong>
                </li>
              )}
            </>
          ) : (
            <>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>구입주체</span>
                <strong className='font-[500]'>개인</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>구입시기</span>
                <strong className='font-[500]'>1개월 이내</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>인수지역</span>
                <strong className='font-[500]'>서울시 중랑구</strong>
              </li>
            </>
          )}
        </ul>
        {state === 'request' && !status ? (
          <V3_Button
            theme='primary'
            className='!h-[50px] !text-[15px] !leading-[15px]'
          >
            견적서 작성하기
          </V3_Button>
        ) : status === 'bidding' ? (
          <V3_Button
            theme='gray'
            className='!h-[50px] !text-[15px] !leading-[15px]'
          >
            작성한 견적서 보기
          </V3_Button>
        ) : status === 'success' ? (
          <V3_Button
            theme='primary'
            className='!h-[50px] !text-[15px] !leading-[15px]'
          >
            계약 진행하기
          </V3_Button>
        ) : null}
      </div>
    </div>
  );
};

export default function DealerMainPage() {
  {
    /* 주문 상태값 (state) > 견적 요청: request, 진행 중: progress, 입찰 종료: closed, 주문 보류: hold, 주문 취소: canceled */
  }
  {
    /* 견적 상태값 (status) > 입찰: bidding, 낙찰: success, 유찰: failed, 주문재개: restart */
  }
  const { setIsBottomTabbar, setTopTabbarState } = useSystemStore();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dealerType = searchParams.get('dealerType') ?? 'newcar';
  const isData = searchParams.get('isData') ?? 'true';

  useLayoutEffect(() => {
    setTopTabbarState({
      mode: 'main',
      state: 'fixed',
    });
    setIsBottomTabbar(true);
  }, []);

  return (
    <div className='w-full min-h-[calc(100dvh-110px)] bg-[#F3F6F9] flex flex-col justify-between items-start'>
      <div className='w-full'>
        <MainCard dealerType={dealerType} isData={isData} />
        {dealerType === 'usedcar' ? (
          <UsedCarRequest isData={isData} dealerType={dealerType} />
        ) : (
          <NewCarRequest isData={isData} dealerType={dealerType} />
        )}
        <ProgressRequest isData={isData} dealerType={dealerType} />
      </div>
      <MainFooter />
    </div>
  );
}
