/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import NoData from 'components/common/v3/NoData';
import V3_Button from 'components_v3/ui/Button';
import { ArrowDown, ChevronDown, X } from 'lucide-react';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';
import CustomDialog from 'pages/publishing/components/customDialog';
import CustomDatePicker from 'pages/publishing/components/customDatePicker';
import { Dayjs } from 'dayjs';

// 진행 중
const ProgressingTab = ({
  isData,
  dealerType,
  setIsOpen,
}: {
  isData: string;
  dealerType: string;
  setIsOpen: (state: boolean) => void;
}) => {
  const [arrowUp, setArrowUp] = useState(false);
  return isData === 'true' ? (
    <>
      <div className='w-full mb-6'>
        <div className='text-[20px] leading-[32px] font-[600] mb-6'>
          3건이 진행 중이에요.
        </div>
        <div className='w-full flex justify-between items-center relative'>
          <div className='flex justify-center items-center gap-1.5'>
            <button
              onClick={() => setIsOpen(true)}
              className='py-2.5 px-2 pl-[14px] rounded-full flex justify-center items-center gap-1.5 bg-white h-[34px] min-w-[70px] text-[14px] leading-[14px] font-[600]'
            >
              상태
              <ChevronDown
                width={18}
                height={18}
                color='#666'
                strokeWidth={2}
              />
            </button>
            <button
              onClick={() => setIsOpen(true)}
              className='py-2.5 px-2 pl-[14px] rounded-full flex justify-center items-center gap-1.5 bg-white h-[34px] min-w-[70px] text-[14px] leading-[14px] font-[600]'
            >
              기간
              <ChevronDown
                width={18}
                height={18}
                color='#666'
                strokeWidth={2}
              />
            </button>
          </div>

          <div
            className='absolute top-1/2 -translate-y-1/2 right-0 py-2.5 pl-[28px]'
            style={{
              background:
                'linear-gradient(to right, #F3F6F900 5px, #F3F6F9 20%)',
            }}
          >
            <button
              onClick={() => setArrowUp(!arrowUp)}
              className='text-[14px] leading-[14px] font-[600] flex justify-center items-center gap-1 text-[#555555] shrink-0'
            >
              최신일자
              <ArrowDown
                width={16}
                height={16}
                color='#7273F9'
                strokeWidth={2}
                className={cn(
                  'transition-all',
                  arrowUp ? 'rotate-180' : 'rotate-0',
                )}
              />
            </button>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-5'>
        <QuoteCard
          state={'progress'}
          status={'bidding'}
          dealerType={dealerType}
        />
        <QuoteCard
          state={'progress'}
          status={'success'}
          dealerType={dealerType}
        />
        <QuoteCard
          state={'progress'}
          status={'restart'}
          dealerType={dealerType}
        />
      </div>
    </>
  ) : (
    <div className='w-full min-h-[calc(100dvh-200px)] flex justify-center items-center pb-[20%]'>
      <NoData
        text='견적함이 비었어요'
        imgUrl='/assets/images/png/noNewCar.png'
      />
    </div>
  );
};
// 진행 내역
const ProgressListTab = ({
  isData,
  dealerType,
  setIsOpen,
}: {
  isData: string;
  dealerType: string;
  setIsOpen: (state: boolean) => void;
}) => {
  const [arrowUp, setArrowUp] = useState(false);
  return isData === 'true' ? (
    <>
      <div className='w-full mb-6'>
        <div className='text-[20px] leading-[32px] font-[600] mb-6'>
          7건을 진행했어요.
        </div>
        <div className='w-full flex justify-between items-center relative'>
          <div className='flex justify-center items-center gap-1.5'>
            <button
              onClick={() => setIsOpen(true)}
              className='py-2.5 px-2 pl-[14px] rounded-full flex justify-center items-center gap-1.5 bg-white h-[34px] min-w-[70px] text-[14px] leading-[14px] font-[600]'
            >
              상태
              <ChevronDown
                width={18}
                height={18}
                color='#666'
                strokeWidth={2}
              />
            </button>
            <button
              onClick={() => setIsOpen(true)}
              className='py-2.5 px-2 pl-[14px] rounded-full flex justify-center items-center gap-1.5 bg-white h-[34px] min-w-[70px] text-[14px] leading-[14px] font-[600]'
            >
              2024.12.10~2025.01.02
              <ChevronDown
                width={18}
                height={18}
                color='#666'
                strokeWidth={2}
              />
            </button>
          </div>
          <div
            className='absolute top-1/2 -translate-y-1/2 right-0 py-2.5 pl-[28px]'
            style={{
              background:
                'linear-gradient(to right, #F3F6F900 5px, #F3F6F9 20%)',
            }}
          >
            <button
              onClick={() => setArrowUp(!arrowUp)}
              className='text-[14px] leading-[14px] font-[600] flex justify-center items-center gap-1 text-[#555555] shrink-0'
            >
              최신일자
              <ArrowDown
                width={16}
                height={16}
                color='#7273F9'
                strokeWidth={2}
                className={cn(
                  'transition-all',
                  arrowUp ? 'rotate-180' : 'rotate-0',
                )}
              />
            </button>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-5'>
        <QuoteCard state={'canceled'} dealerType={dealerType} />
        <QuoteCard state={'hold'} dealerHold={true} dealerType={dealerType} />
        <QuoteCard state={'hold'} dealerType={dealerType} />
        <QuoteCard state={'closed'} status={'failed'} dealerType={dealerType} />
        <QuoteCard state={'completed'} dealerType={dealerType} />
        <QuoteCard
          state={'completed'}
          isReview={true}
          dealerType={dealerType}
        />
        <QuoteCard
          state={'completed'}
          isReview={true}
          isSettlement={true}
          dealerType={dealerType}
        />
      </div>
      <div className='w-full mt-[30px] rounded-[10px] bg-[#E5EAEE] p-4 pb-[20px] flex flex-col justify-start items-start gap-[20px]'>
        <h5 className='w-full text-[15px] leading-[22.5px] text-[#222] font-[500] pb-4 border-b border-[#D6D6DC]'>
          확인해 주세요
        </h5>
        <ul className='text-[#555555] flex flex-col justify-start items-start gap-[12px]'>
          <li className='flex justify-start items-center relative text-[14px] break-keep leading-[21px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
            계약이 완료된 후 메시지 및 알림을 통해 수수료 정산 요청이 자동
            발송됩니다.
          </li>
          <li className='flex justify-start items-center relative text-[14px] break-keep leading-[21px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
            수수료 정산 관련 내용은 마이 페이지 {'>'} 수수료 정산에서 확인할 수
            있습니다.
          </li>
        </ul>
      </div>
    </>
  ) : (
    <div className='w-full min-h-[calc(100dvh-200px)] flex justify-center items-center pb-[20%]'>
      <NoData
        text='견적함이 비었어요'
        imgUrl='/assets/images/png/noNewCar.png'
      />
    </div>
  );
};

// 견적함 카드
const QuoteCard = ({
  state,
  status,
  dealerType,
  dealerHold,
  isReview,
  isSettlement,
}: {
  state: string;
  status?: string;
  dealerType?: string;
  dealerHold?: boolean;
  isReview?: boolean;
  isSettlement?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate('/dealer/quote/detail/01')}
      className={cn(
        'w-full p-4 pt-[15px] bg-white rounded-[16px] relative shadow-[0px_6px_20px_#0000000D]',
        status === 'failed' && 'pb-5',
      )}
    >
      <div
        className={cn(
          'flex justify-between items-center gap-2.5 border-b border-[#E5EAEE] pb-[15px]',
          status === undefined && 'pb-[19px] pt-1',
        )}
      >
        <div className='flex justify-start items-center gap-2.5 tiny:gap-1.5'>
          {/* 주문 상태값 : state */}
          <strong
            className={cn(
              'text-[16px] leading-[16px] font-[600] shrink-0',
              state === 'closed' && 'text-[#999999]',
              state === 'hold' && 'text-[#FF7B2E]',
              state === 'canceled' && 'text-[#FF4747]',
              state === 'completed' && 'text-[#005CE6]',
            )}
          >
            {state === 'progress'
              ? '진행 중 '
              : state === 'hold'
                ? '주문 보류'
                : state === 'canceled'
                  ? '주문 취소'
                  : state === 'completed'
                    ? '주문 완료'
                    : '입찰 종료'}
          </strong>
          {/* 견적 진행 상태값 : status */}
          {status === 'success' ? (
            <div className='text-[14px] leading-[14px] font-[600] text-[#005CE6] flex justify-center items-center min-w-[36px] px-1.5 h-[28px] bg-[#E3EEFF] rounded-[6px]'>
              낙찰
            </div>
          ) : status === 'failed' ? (
            <div className='text-[14px] leading-[14px] font-[600] text-[#999999] flex justify-center items-center min-w-[36px] px-1.5 h-[28px] bg-[#F1F4F6] rounded-[6px]'>
              유찰
            </div>
          ) : status === 'restart' ? (
            <div className='text-[14px] leading-[14px] font-[600] text-[#009E59] flex justify-center items-center min-w-[36px] px-1.5 h-[28px] bg-[#E0F3EB] rounded-[6px]'>
              주문 재개
            </div>
          ) : status !== undefined ? (
            <div className='flex justify-start pl-1 items-center gap-1 min-w-[121px] h-[28px] tiny:min-w-[108px] rounded-[6px] bg-[#FFF0F0]'>
              <img
                src='/assets/images/png/clock.png'
                alt='시계 아이콘'
                className='w-4 object-contain'
              />
              <span className='text-[14px] leading-[14px] font-[600] text-[#FF4747] tiny:text-[12px] tiny:leading-[12px]'>
                29일 02:00:00
              </span>
            </div>
          ) : null}
        </div>
        <span className='text-[13px] leading-[19.5px] font-[500] text-[#999] tiny:text-[12px] tiny:leading-[18px]'>
          24.12.24 20:00
        </span>
      </div>
      <div className='py-4 border-b border-[#E5EAEE] flex justify-start items-center gap-4 tiny:gap-2.5'>
        {dealerType === 'usedcar' ? (
          <div className='w-[40%] aspect-[120/90] max-w-[160px] min-w-[110px] bg-[#F3F6F9] flex justify-center items-center rounded-[10px] overflow-hidden'>
            <img
              src='/assets/images/png/ex-used-car.png'
              alt='예시 차량 사진'
              className='w-full h-full object-cover'
            />
          </div>
        ) : (
          <div className='w-[40%] aspect-[120/90] max-w-[160px] min-w-[110px] bg-[#F3F6F9] flex justify-center items-center py-[18px] px-[15px] rounded-[10px]'>
            <img src='/assets/images/png/ex-car.png' alt='예시 차량 사진' />
          </div>
        )}

        <div className='w-full flex flex-col justify-start items-start gap-1'>
          <span className='inline-block mb-0.5 text-[14px] leading-[14px] font-[500] text-[#555555] tiny:text-[13px] tiny:leading-[13px]'>
            {dealerType === 'usedcar' ? '내 차 팔기' : '일시불'}
          </span>
          <strong className='text-[14px] leading-[22.4px] font-[600] break-keep tiny:text-[13px] tiny:leading-[20.8px]'>
            기아 GLE-클래스 24년식
          </strong>
          {dealerType === 'usedcar' ? (
            <p className='text-[13px] leading-[20.8px] font-[500] text-[#555555] break-keep'>
              33부 0777
            </p>
          ) : (
            <p className='w-[calc(100%-10px)] text-[13px] leading-[20.8px] font-[500] text-[#555555] truncate tiny:text-[12px] tiny:leading-[19.2px]'>
              가솔린 2.5 터보 2WD 기본형
            </p>
          )}
        </div>
      </div>
      <div className='pt-4 flex flex-col justify-start items-start gap-6'>
        <ul className='flex flex-col justify-start items-start gap-[14px] text-[15px] leading-[15px]'>
          <li className='flex justify-start items-center'>
            <span className='min-w-[75px]'>요청일</span>
            <strong className='font-[500]'>2025.01.06</strong>
          </li>
          {dealerType === 'usedcar' ? (
            <>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>연식</span>
                <strong className='font-[500]'>2020년형</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>주행거리</span>
                <strong className='font-[500]'>171,100km</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>판매지역</span>
                <strong className='font-[500]'>서울시 중랑구</strong>
              </li>
              {(status === 'bidding' || status === 'failed') && (
                <li className='flex justify-start items-center'>
                  <span className='min-w-[75px]'>입찰가</span>
                  <strong className='font-[500]'>1,200만원</strong>
                </li>
              )}
              {status !== 'bidding' && (
                <li className='flex justify-start items-center'>
                  <span className='min-w-[75px]'>낙찰가</span>
                  <strong className='font-[500]'>1,200만원</strong>
                </li>
              )}
            </>
          ) : (
            <>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>구입주체</span>
                <strong className='font-[500]'>개인</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>구입시기</span>
                <strong className='font-[500]'>1개월 이내</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>인수지역</span>
                <strong className='font-[500]'>서울시 중랑구</strong>
              </li>
            </>
          )}
        </ul>
        {/* 주문 진행중 액션 버튼 */}
        {state === 'request' && !status ? (
          <V3_Button
            theme='primary'
            className='!h-[50px] !text-[15px] !leading-[15px]'
          >
            견적서 작성하기
          </V3_Button>
        ) : status === 'bidding' ? (
          <V3_Button
            theme='gray'
            className='!h-[50px] !text-[15px] !leading-[15px]'
          >
            작성한 견적서 보기
          </V3_Button>
        ) : status === 'success' || status === 'restart' ? (
          <V3_Button
            theme='primary'
            className='!h-[50px] !text-[15px] !leading-[15px]'
          >
            계약 진행하기
          </V3_Button>
        ) : null}
      </div>
      {/* 취소사유 */}
      {state === 'canceled' && (
        <div className='w-full flex justify-between items-center gap-2.5 border-[#E5EAEE] border-t mt-4 pt-4 pb-1'>
          <span className='text-[14px] leading-[19.6px] font-[500] text-[#7D848A]'>
            취소 사유
          </span>
          <strong className='text-[14px] leading-[19.6px] font-[500]'>
            [고객] 카 매니저와의 갈등
          </strong>
        </div>
      )}
      {/* 보류 사유 */}
      {state === 'hold' && (
        <div
          className={cn(
            'w-full border-[#E5EAEE] border-t mt-4 pt-4',
            !dealerHold && 'pb-1',
          )}
        >
          <div className='w-full flex justify-between items-center gap-2.5'>
            <span className='text-[14px] leading-[19.6px] font-[500] text-[#7D848A]'>
              보류 사유
            </span>
            <strong className='text-[14px] leading-[19.6px] font-[500]'>
              {dealerHold
                ? '[카 매니저] 개인적인 사유'
                : '[고객] 개인적인 사유'}
            </strong>
          </div>
          {dealerHold === true && (
            <div className='w-full flex justify-center items-center gap-[7px] mt-6'>
              <V3_Button className='!h-[50px] !text-[15px]'>재주문</V3_Button>
              <V3_Button className='!h-[50px] !text-[15px]'>
                주문 취소
              </V3_Button>
            </div>
          )}
        </div>
      )}
      {/* 주문 완료시 액션 버튼 */}
      {state === 'completed' && (
        <div className={cn('w-full mt-6')}>
          <div className='w-full flex justify-center items-center gap-[7px] mt-6'>
            {isReview && (
              <V3_Button
                className='!h-[50px] !text-[15px] whitespace-nowrap'
                theme='gray'
              >
                받은 리뷰 보기
              </V3_Button>
            )}
            {isSettlement ? (
              <V3_Button
                className='!h-[50px] !text-[15px] whitespace-nowrap'
                theme='gray'
              >
                수수료 정산 내역
              </V3_Button>
            ) : (
              <V3_Button className='!h-[50px] !text-[15px]' theme='lightBlue'>
                수수료 정산
              </V3_Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default function DealerQuotePage() {
  {
    /* 주문 상태값 (state) >  진행 중: progress, 입찰 종료: closed, 주문 보류: hold, 주문 취소: canceled, 주문 완료: completed */
  }
  {
    /* 견적 상태값 (status) > 입찰: bidding, 낙찰: success, 유찰: failed, 주문재개: restart */
  }
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isData = searchParams.get('isData') ?? 'true';
  const dealerType = searchParams.get('dealerType') ?? 'newcar';
  const [currentTab, setCurrentTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [orderState, setOrderState] = useState('전체');
  const [orderRange, setOrderRange] = useState('전체');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'my',
      title: '견적함',
    });
    setIsBottomTabbar(true);
  }, []);
  return (
    <div className='w-full min-h-[calc(100dvh-110px)] bg-[#F3F6F9] flex flex-col justify-start items-start pb-[50px] '>
      <ul className='sticky z-30 w-full top-0 left-0 grid grid-cols-2 bg-white border-b border-[#E5E5EC]'>
        <li
          onClick={() => setCurrentTab(0)}
          className={cn(
            'p-[17px] flex justify-center items-center text-[16px] leading-[16px] font-[500] text-[#999] transition-all',
            currentTab === 0 && 'text-[#222]',
          )}
        >
          진행 중
        </li>
        <li
          onClick={() => setCurrentTab(1)}
          className={cn(
            'p-[17px] flex justify-center items-center text-[16px] leading-[16px] font-[500] text-[#999] transition-all',
            currentTab === 1 && 'text-[#222]',
          )}
        >
          진행 내역
        </li>
        <span
          className={cn(
            'absolute w-1/2 bottom-[-1px]  h-[2px] bg-[#222] transition-all',
            currentTab === 0 ? 'left-0' : 'left-1/2',
          )}
        ></span>
      </ul>
      <div className='w-full pt-[30px] px-5'>
        {currentTab === 0 && (
          <ProgressingTab
            isData={isData}
            dealerType={dealerType}
            setIsOpen={setIsOpen}
          />
        )}
        {currentTab === 1 && (
          <ProgressListTab
            isData={isData}
            dealerType={dealerType}
            setIsOpen={setIsOpen}
          />
        )}
      </div>
      {/* 필터 */}
      <CustomDialog open={isOpen} setIsOpen={(open) => setIsOpen(open)}>
        <div className='w-full pt-1.5 pb-4 px-2.5 flex justify-center items-center relative'>
          <span className='text-[20px] leading-[20px] font-[600]'>필터</span>
          <button
            onClick={() => setIsOpen(false)}
            className='absolute right-2.5 top-[1px]'
          >
            <X width={28} height={28} strokeWidth={1.5} />
          </button>
        </div>
        <div className='p-2.5 pt-5 w-full flex flex-col justify-between items-start gap-[50px] min-h-[448px]'>
          <div className='w-full flex flex-col justify-start items-start gap-[30px]'>
            <div>
              <strong className='inline-block mb-4 text-[15px] leading-[15px] font-[600]'>
                주문 상태
              </strong>
              <ul className='flex justify-start items-center flex-wrap gap-1.5 text-[14px] leading-[14px] font-[500]'>
                <li
                  onClick={() => setOrderState('전체')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderState === '전체' && 'border-[#7273F9]',
                  )}
                >
                  전체
                </li>
                <li
                  onClick={() => setOrderState('주문 완료')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderState === '주문 완료' && 'border-[#7273F9]',
                  )}
                >
                  주문 완료
                </li>
                <li
                  onClick={() => setOrderState('주문 보류')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderState === '주문 보류' && 'border-[#7273F9]',
                  )}
                >
                  주문 보류
                </li>
                <li
                  onClick={() => setOrderState('주문 취소')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderState === '주문 취소' && 'border-[#7273F9]',
                  )}
                >
                  주문 취소
                </li>
                <li
                  onClick={() => setOrderState('입찰 종료')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderState === '입찰 종료' && 'border-[#7273F9]',
                  )}
                >
                  입찰 종료
                </li>
              </ul>
            </div>
            <div>
              <strong className='inline-block mb-4 text-[15px] leading-[15px] font-[600]'>
                기간
              </strong>
              <ul className='flex justify-start items-center flex-wrap gap-1.5 text-[14px] leading-[14px] font-[500]'>
                <li
                  onClick={() => setOrderRange('전체')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderRange === '전체' && 'border-[#7273F9]',
                  )}
                >
                  전체
                </li>
                <li
                  onClick={() => setOrderRange('3개월')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderRange === '3개월' && 'border-[#7273F9]',
                  )}
                >
                  3개월
                </li>
                <li
                  onClick={() => setOrderRange('6개월')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderRange === '6개월' && 'border-[#7273F9]',
                  )}
                >
                  6개월
                </li>
                <li
                  onClick={() => setOrderRange('1년')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderRange === '1년' && 'border-[#7273F9]',
                  )}
                >
                  1년
                </li>
                <li
                  onClick={() => setOrderRange('직접설정')}
                  className={cn(
                    'flex justify-center items-center py-3 px-4 whitespace-nowrap w-[79px] h-[40px] rounded-[8px] border border-[#E5E5EC] bg-white transition-all',
                    orderRange === '직접설정' && 'border-[#7273F9]',
                  )}
                >
                  직접설정
                </li>
              </ul>
              {orderRange === '직접설정' && (
                <div className='flex justify-start items-center gap-1 mt-1.5'>
                  <CustomDatePicker
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                  <span>~</span>
                  <CustomDatePicker
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='w-full flex justify-center items-center gap-2.5'>
            <V3_Button
              className='!w-[85px] whitespace-nowrap'
              onClick={() => {
                setOrderState('전체');
                setOrderRange('전체');
                setStartDate(null);
                setEndDate(null);
              }}
            >
              초기화
            </V3_Button>
            <V3_Button theme='primary' onClick={() => setIsOpen(false)}>
              적용
            </V3_Button>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
}
