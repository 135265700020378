import NoData from 'components/common/v3/NoData';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import V3_Button from 'components_v3/ui/Button';
import { ArrowDown } from 'lucide-react';
import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

// 구매요청 카드
const RequestCard = ({
  isNew,
  dealerType,
}: {
  isNew: boolean;
  dealerType: string;
}) => {
  return (
    <div
      className={cn(
        'w-full p-4 pt-[15px] bg-white rounded-[16px] relative shadow-[0px_6px_20px_#0000000D]',
      )}
    >
      {isNew === true && (
        <span className='w-2 h-2 rounded-full bg-[#7273F9] absolute top-3 right-3'></span>
      )}
      <div className='flex justify-between items-center gap-4 border-b border-[#E5EAEE] pb-[15px]'>
        <div className='flex justify-start items-center gap-2.5'>
          <strong className={cn('text-[16px] leading-[16px] font-[600]')}>
            견적 요청
          </strong>

          <div className='flex justify-center items-center gap-1 min-w-[121px]  tiny:min-w-[108px] h-[28px] rounded-[6px] bg-[#FFF0F0]'>
            <img
              src='/assets/images/png/clock.png'
              alt='시계 아이콘'
              className='w-4 object-contain'
            />
            <span className='text-[14px] leading-[14px] font-[600] tiny:text-[12px] tiny:leading-[12px] text-[#FF4747]'>
              29일 02:00:00
            </span>
          </div>
        </div>
      </div>
      <div className='py-4 border-b border-[#E5EAEE] flex justify-start items-center gap-4  tiny:gap-2.5'>
        {dealerType === 'usedcar' ? (
          <div className='w-[40%] aspect-[120/90] max-w-[160px] min-w-[110px] bg-[#F3F6F9] flex justify-center items-center rounded-[10px] overflow-hidden'>
            <img
              src='/assets/images/png/ex-used-car.png'
              alt='예시 차량 사진'
              className='w-full h-full object-cover'
            />
          </div>
        ) : (
          <div className='w-[40%] aspect-[120/90] max-w-[160px] min-w-[110px] bg-[#F3F6F9] flex justify-center items-center py-[18px] px-[15px] rounded-[10px]'>
            <img src='/assets/images/png/ex-car.png' alt='예시 차량 사진' />
          </div>
        )}

        <div className='flex flex-col justify-start items-start gap-1'>
          <span className='inline-block mb-0.5 text-[14px] leading-[14px] font-[500] text-[#555555] tiny:text-[13px] tiny:leading-[13px]'>
            {dealerType === 'usedcar' ? '내 차 팔기' : '일시불'}
          </span>
          <strong className='text-[14px] leading-[22.4px] font-[600] break-keep tiny:text-[13px] tiny:leading-[20.8px]'>
            기아 GLE-클래스 24년식
          </strong>
          {dealerType === 'usedcar' ? (
            <p className='text-[13px] leading-[20.8px] font-[500] text-[#555555] break-keep'>
              33부 0777
            </p>
          ) : (
            <p className='w-[calc(100%-10px)] text-[13px] leading-[20.8px] font-[500] text-[#555555] truncate tiny:text-[12px] tiny:leading-[19.2px]'>
              가솔린 2.5 터보 2WD 기본형
            </p>
          )}
        </div>
      </div>
      <div className='pt-4 flex flex-col justify-start items-start gap-6'>
        <ul className='flex flex-col justify-start items-start gap-[14px] text-[15px] leading-[15px]'>
          <li className='flex justify-start items-center'>
            <span className='min-w-[75px]'>요청일</span>
            <strong className='font-[500]'>2025.01.06</strong>
          </li>
          {dealerType === 'usedcar' ? (
            <>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>연식</span>
                <strong className='font-[500]'>2020년형</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>주행거리</span>
                <strong className='font-[500]'>171,100km</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>판매지역</span>
                <strong className='font-[500]'>서울시 중랑구</strong>
              </li>
              {(status === 'bidding' || status === 'failed') && (
                <li className='flex justify-start items-center'>
                  <span className='min-w-[75px]'>입찰가</span>
                  <strong className='font-[500]'>1,200만원</strong>
                </li>
              )}
              {status === 'success' && (
                <li className='flex justify-start items-center'>
                  <span className='min-w-[75px]'>낙찰가</span>
                  <strong className='font-[500]'>1,200만원</strong>
                </li>
              )}
            </>
          ) : (
            <>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>구입주체</span>
                <strong className='font-[500]'>개인</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>구입시기</span>
                <strong className='font-[500]'>1개월 이내</strong>
              </li>
              <li className='flex justify-start items-center'>
                <span className='min-w-[75px]'>인수지역</span>
                <strong className='font-[500]'>서울시 중랑구</strong>
              </li>
            </>
          )}
        </ul>
        <V3_Button
          theme='primary'
          className='!h-[50px] !text-[15px] !leading-[15px]'
        >
          견적서 작성하기
        </V3_Button>
      </div>
    </div>
  );
};

export default function RequestMainPage() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isData = searchParams.get('isData') ?? 'true';
  const dealerType = searchParams.get('dealerType') ?? 'newcar';
  const [arrowUp, setArrowUp] = useState(false);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'my',
      title: '견적 요청',
    });
    setIsBottomTabbar(true);
  }, []);
  return (
    <div className='w-full min-h-[calc(100dvh-110px)] bg-[#F3F6F9] flex flex-col justify-start items-start pt-[30px] pb-[50px] px-5'>
      {isData === 'true' ? (
        <>
          <div className='w-full mb-6'>
            <div className='text-[20px] leading-[32px] font-[600] mb-6'>
              2건의 요청을 받았어요.
            </div>
            <div className='w-full flex justify-between items-center'>
              <div className='max-w-[70px]'>
                <SelectV3
                  value={'기간'}
                  type='noBorder'
                  onValueChange={() => {}}
                >
                  <SelectV3Item value={'기간'}>기간</SelectV3Item>
                </SelectV3>
              </div>

              <button
                onClick={() => setArrowUp(!arrowUp)}
                className='text-[14px] leading-[14px] font-[600] flex justify-center items-center gap-1 text-[#555555]'
              >
                최신일자
                <ArrowDown
                  width={16}
                  height={16}
                  color='#7273F9'
                  strokeWidth={2}
                  className={cn(
                    'transition-all',
                    arrowUp ? 'rotate-180' : 'rotate-0',
                  )}
                />
              </button>
            </div>
          </div>
          <div className='grid grid-cols-1 gap-5'>
            <RequestCard isNew={true} dealerType={dealerType} />
            <RequestCard isNew={false} dealerType={dealerType} />
          </div>
        </>
      ) : (
        <div className='w-full min-h-[calc(100dvh-200px)] flex justify-center items-center pb-[20%]'>
          <NoData
            text='받은 견적 요청이 없어요'
            imgUrl='/assets/images/png/noNewCar.png'
          />
        </div>
      )}
    </div>
  );
}
