/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import InsuranceBanner from './component/banner';
import { ArrowUp, ChevronLeft } from 'lucide-react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import {
  addHistoryInsuranceAdLinkClick,
  addHistoryInsuranceCooconClick,
} from 'apis/history';
import useDeviceStore from 'store/useDeviceStore';
import { useQuery } from '@tanstack/react-query';
import { getInsuranceProducts } from 'apis/data';
import { CircularProgress } from '@mui/material';
import { cn } from 'utils';

export default function InsuranceMain() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { data: InsuranceCompanyData, isFetching } = useQuery({
    queryKey: ['InsuranceCompanyData'],
    queryFn: () => {
      return getInsuranceProducts();
    },
  });

  // 카사요 앱 외부에서 접근 시 : ex. insurance
  const externalAccessKey = searchParams.get('type');
  const { isBottomTabbar, setTopTabbarState, setIsBottomTabbar } =
    useSystemStore();
  const { myDevice } = useDeviceStore();

  const outerDivRef = useRef<HTMLDivElement>(null);
  const scrollTopRef = useRef<SVGSVGElement>(null);
  const companySelecteRef = useRef<null[] | HTMLDivElement[]>([]);

  const handleClickEvents = {
    moveToItem: (targetIndex: number) => {
      if (!InsuranceCompanyData) return;

      companySelecteRef.current[targetIndex]?.scrollIntoView({
        inline: 'center',
        block: 'center',
        behavior: 'smooth',
      });
    },
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        window.open(
          'https://insu-dev.coocon.co.kr/bridge.act?org_cd=RUQBBRKU&browser_type=2',
        );
        return;
      }
      window.open(
        'https://insu.coocon.net/bridge.act?org_cd=RUQBBRKU&browser_type=2',
      );
    },
  };

  useEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <ChevronLeft
          strokeWidth={1.5}
          onClick={() => {
            navigate(-1);
          }}
          className='h-7 w-7 cursor-pointer'
        ></ChevronLeft>
      ),
      title: '자동차 보험',
    });
  }, []);

  useEffect(() => {
    if (!outerDivRef.current) return;

    function handleScrollTopButton() {
      if (!outerDivRef.current || !scrollTopRef.current) return;
      if (outerDivRef.current.scrollTop > 2500) {
        if (scrollTopRef.current.style.display !== 'block')
          scrollTopRef.current.style.display = 'block';
      } else {
        if (scrollTopRef.current.style.display !== 'none') {
          scrollTopRef.current.style.display = 'none';
        }
      }
    }

    outerDivRef.current.addEventListener('scroll', handleScrollTopButton);
    return () => {
      if (!outerDivRef.current) return;
      outerDivRef.current.removeEventListener(
        'scrollend',
        handleScrollTopButton,
      );
    };
  }, []);

  useLayoutEffect(() => {
    setIsBottomTabbar(false);

    if (isBottomTabbar === true) {
      return () => {
        setIsBottomTabbar(true);
      };
    }
  }, []);

  return (
    <div
      ref={outerDivRef}
      className='flex flex-col h-full overflow-y-scroll bg-[#F3F6F9] pb-[50px]'
    >
      {/* 자동차 보험 비교하기 */}
      {externalAccessKey !== 'insurance' && (
        <section className='flex items-center flex-col pb-3 bg-[#F0F0FD]'>
          <div className='flex w-full justify-between items-center px-5 py-[17px] tiny:pr-1.5'>
            <div className='flex flex-auto w-full'>
              <div className='text-[#222] font-semibold text-[18px] leading-[27px] tracking-[-0.45px]'>
                보험사별로 금액 비교하고
                <br />
                <span className='text-[#494AF1]'>직접 가입</span>하면 더욱
                저렴해요.
              </div>
            </div>

            <div className='w-fit flex-none'>
              <img
                src='/assets/images/insurance/auth_phone.png'
                alt='보험 가입'
                className='w-[100px] aspect-square tiny:w-[80px]'
              />
            </div>
          </div>

          <div className='flex flex-col w-full px-5'>
            <div
              className={cn(
                'h-[88px] flex items-center justify-between bg-white pl-4 pr-2.5 rounded-t-[10px]',
                'border-b border-dashed border-[#DBDBE7] ',
              )}
            >
              <div className='flex flex-col text-[#222] font-semibold text-[15px] leading-[22.5px] tracking-[-0.375px]'>
                <div className='text-[#6263CF]'>STEP 1</div>
                <div>나에게 맞는 최저가 보험사 확인 후</div>
              </div>
              <div>
                <img
                  src='/assets/images/insurance/step1.png'
                  alt='보험 가입'
                  className='w-[60px] aspect-square tiny:w-[50px]'
                />
              </div>
            </div>
            <div className='h-[88px] flex items-center justify-between bg-white pl-4 pr-2.5 rounded-b-[10px]'>
              <div className='flex flex-col text-[#222] font-semibold text-[15px] leading-[22.5px] tracking-[-0.375px]'>
                <div className='text-[#6263CF]'>STEP 2</div>
                <div>더 알뜰하게 직접 가입하기</div>
              </div>
              <div>
                {' '}
                <img
                  src='/assets/images/insurance/step2.png'
                  alt='보험 가입'
                  className='w-[60px] aspect-square tiny:w-[50px]'
                />
              </div>
            </div>
          </div>

          <div className='px-5 pt-6 w-full'>
            <Button
              className='rounded-full bg-[#6A6BE3] text-[#FFF] font-semibold text-[15px] leading-[22.5px] tracking-[-0.375px] h-[52px]'
              onClick={handleClickEvents.openCooconInsurance}
            >
              자동차 보험 비교하기
            </Button>
            <div className='w-full pt-3 flex gap-2 justify-between text-[11px] leading-[16.5px] font-[400] text-[#999999]'>
              <div>
                <span>손해보험협회 심의필 제129622호</span>
                <br className='hidden tiny:inline' />
                <span>(2024.08.01 ~ 2025.07.31)</span>
              </div>
              <div className='shrink-0'>쿠콘</div>
            </div>
          </div>
        </section>
      )}

      {/* 다이렉트 자동차보험 가입 */}
      {isFetching ? (
        <div className='flex-auto flex items-center justify-center'>
          <CircularProgress />
        </div>
      ) : (
        <>
          <section className='flex items-center flex-col pt-[30px]'>
            <div className='flex items-center justify-between w-full px-[20px]'>
              <div className='flex gap-2 items-center'>
                <span className='text-[#222] font-semibold text-[18px] leading-[27px] tracking-[-0.36px]'>
                  다이렉트 자동차보험 직접 가입
                </span>
              </div>
              <Button className='w-[54px] h-[32px] text-[#6100FF] bg-[#EEE3FF] rounded-[4px] text-[16px] font-[600] leading-[16px]'>
                AD
              </Button>
            </div>

            <div className='flex flex-none px-[17px] py-6 h-fit overflow-x-auto max-w-full'>
              {InsuranceCompanyData &&
                InsuranceCompanyData.map((el, index) => {
                  return (
                    <div
                      key={el.id}
                      onClick={() => {
                        handleClickEvents.moveToItem(index);
                      }}
                      className={
                        'flex flex-none cursor-pointer bg-white hover:bg-gray-50 h-fit gap-2.5 mx-[3px] px-4 py-3 rounded-full border border-[#E5E5EC] flex-shrink-0 justify-center items-center'
                      }
                    >
                      <img
                        className='w-5 h-5'
                        src={el.logo_url}
                        alt='보험사 로고'
                      />
                      <div className='text-[14px] leading-[14px] font-[500]'>
                        {el.name}
                      </div>
                    </div>
                  );
                })}
            </div>
          </section>

          {/* 보험사별 혜택 영역 */}
          <section className='flex items-center flex-col px-5 gap-5 mb-[30px]'>
            {InsuranceCompanyData &&
              InsuranceCompanyData.map((el, index) => {
                return (
                  <div
                    key={el.id}
                    ref={(el) => (companySelecteRef.current[index] = el)}
                    className='bg-white p-4 w-full rounded-[16px] shadow-[0px_6px_20px_#0000000D]'
                    onClick={() => {
                      addHistoryInsuranceAdLinkClick({
                        from: `app${location.pathname}`,
                        target: el.name,
                      });

                      if (el.direct.signup_url_mobile) {
                        if (myDevice === 'ios' || myDevice === 'android') {
                          window.open(el.direct.signup_url_mobile);
                          return;
                        }
                      }

                      if (el.direct.signup_url_web) {
                        window.open(el.direct.signup_url_web);
                      }

                      return;
                    }}
                  >
                    <InsuranceBanner
                      className='w-full mb-5'
                      data={el}
                    ></InsuranceBanner>

                    {/* Description */}
                    <div className='flex border-b pb-4 border-[#E5E5EC]'>
                      <div
                        className={`flex flex-col justify-between flex-grow`}
                      >
                        <span
                          className={`text-[14px] leading-[22.4px] font-normal tracking-[-0.025em] text-[#555555] break-all`}
                          dangerouslySetInnerHTML={{
                            __html: el.direct.description,
                          }}
                        ></span>
                      </div>
                    </div>

                    <div className='mt-[14px] flex items-center justify-between w-full gap-6'>
                      <span
                        className={`text-[11px] text-[#999999] font-[400] leading-[16.5px] tracking-[-0.025em] break-keep whitespace-pre-line`}
                      >
                        {el.direct.certification}
                      </span>

                      <Button className='w-[54px] h-[32px] text-[#6100FF] bg-[#EEE3FF] rounded-[4px] text-[16px] font-[600] leading-[16px]'>
                        AD
                      </Button>
                    </div>
                  </div>
                );
              })}
          </section>

          {/* 안내사항 영역 */}
          <section className='flex flex-col p-4 pb-[20px] bg-[#E7EBEF] tracking-[-0.025em] w-[calc(100%-40px)] mx-auto rounded-[10px]'>
            <span className='text-[15px] font-[500] leading-[22.5px] tracking-[-0.02em] pb-4 mb-5 border-b border-[#F3F6F9]'>
              보험계약 시 알아두실 사항 확인해 주세요
            </span>

            <ul className='list-decimal pl-4 text-[#555555] break-all font-[400] text-[13px] leading-[19.5px]'>
              <li className='pl-0.5 mb-3'>
                차 보험 광고(차 보험 가입하기)는 카사요(주)가 제휴 보험회사의
                다이렉트 자동차 보험상품을 광고하는 서비스입니다. 본 광고
                서비스를 통하여 카사요(주)는 상품 판매 및 중개에 관여하지
                않습니다.
              </li>
              <li className='pl-0.5 mb-3'>
                해당 보험사는 관련 상품에 대해 충분히 설명할 의무가 있으며,
                가입자는 가입에 앞서 이에 대한 충분한 설명을 받으시길 바랍니다.
              </li>
              <li className='pl-0.5 mb-3'>
                보험계약 체결 전 상품설명서 및 약관을 읽어보시길 바랍니다.
              </li>
              <li className='pl-0.5 mb-3'>
                보험계약자가 기존에 체결했던 보험계약을 해지하고, 다른
                보험계약을 체결할 때는 보험인수가 거절되거나 보험료가 인상되거나
                보장 내용이 달라질 수 있습니다.
              </li>
              <li className='pl-0.5 mb-3'>
                지급 한도, 면책 사항 등에 따라 보험금 지급이 제한될 수 있습니다.
              </li>
              <li className='pl-0.5 mb-3'>
                이 보험계약은 예금자보호법에 따라 해약환급금(또는 만기 시
                보험금)에 기타 지급금을 합한 금액이 1인당 “5천만 원까지”(본
                보험회사의 여타 보험상품과 합산) 보호됩니다. 이와 별도로 본
                보험회사 보호 상품의 사고 보험금을 합산한 금액이 1인당 “5천만
                원까지”보호됩니다.
              </li>
              <li className='pl-0.5 mb-3'>
                한 보험사의 차 보험 가입 광고로 받을 수 있는 혜택은 최대
                30,000원입니다.
              </li>
              <li className='pl-0.5 mb-3'>
                차 보험 가입 광고의 상세 내용은 각 보험사 페이지에서 확인할 수
                있습니다.
              </li>
              <li className='pl-0.5 mb-3'>
                차 보험 가입 이후 포인트 지급 관련 문의는 각 보험사에 문의해
                주세요.
              </li>
              <li className='pl-0.5 mb-3'>
                본 광고는 광고 심의 기준을 준수하였으며, 유효기간은 각 보험사
                심의일로부터 1년입니다.
              </li>
            </ul>
          </section>
        </>
      )}

      <ArrowUp
        ref={scrollTopRef}
        onClick={() => {
          outerDivRef.current?.scroll({
            top: 0,
            behavior: 'smooth',
          });
        }}
        style={{
          display: 'none',
        }}
        className='p-2.5 w-10 h-10 active:bg-[#00000080] rounded-full mb-[var(--safeAreaBottomPadding)] fixed right-5 bottom-5 bg-[#00000080] text-white'
      >
        맨위로가기
      </ArrowUp>
    </div>
  );
}
