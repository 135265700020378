/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { ChevronLeft } from 'lucide-react';
import RepairEmpty from '../../component/RepairEmpty';
import useCurrentLocationStore from 'store/useCurrentLocation';
import { cn } from 'utils';
import { useQuery } from '@tanstack/react-query';
import { getTotalRepairshopList } from 'apis/accidentReport';
import RepairShopCard from './repairShopCard';
import { CircularProgress } from '@mui/material';
import SelectLocationSubpage from './selectLocation';
import { RepairShop } from '@carsayo/types';
import { getDistance } from 'geolib';
import RepairDetailSubPage from './repairDetail';
import is영업중 from 'pages/v3/accidentReport/utils/checkWorkingMode';
import V3_Button from 'components_v3/ui/Button';
import { useDataStore } from 'store/useDataStore';
import useMemberStore from 'store/useMemberStore';

export type RepairShopListFilter = {
  working_status: 'all' | 'open' | 'closed';
  towing_available: boolean;
  keyword: string;
  location: {
    address: string | null;
    lat: number | null;
    lng: number | null;
  };
};

const RepairMainList = () => {
  const { loginMember } = useMemberStore();
  const { holiday } = useDataStore();

  const { data: totalRepairshopList, isLoading } = useQuery({
    queryKey: ['totalRepairshopList'],
    queryFn: async () => {
      return (await getTotalRepairshopList()).map((el) => {
        return {
          ...el,
          isWorking: is영업중(el, holiday),
        };
      });
    },
    staleTime: 1000 * 60 * 1,
    enabled: !!holiday,
  });

  const [filter, setFilter] = useState<RepairShopListFilter>({
    working_status: 'all',
    towing_available: false,
    keyword: '',
    location: {
      address: null,
      lat: null,
      lng: null,
    },
  });

  const filteredRepairshopList = useMemo(() => {
    if (!totalRepairshopList) return [];

    return totalRepairshopList?.filter((el) => {
      /** 위도경도 데이터 없을 경우 제낌 */
      if (!el.location.latitude) return false;

      /** 견인 가능 여부 필터링
       * @note 견인가능이 체크되었을 때에 비견인 공업사 제외
       */
      if (filter.towing_available === true) {
        if (el.towing_available === false) {
          return false;
        }
      }

      /** 검색어 필터링 */
      if (filter.keyword) {
        if (!el.name.includes(filter.keyword)) return false;
      }

      /** 주소 필터링
       * @note 주소만 설정되어 있을 경우 시도명으로 공업사 주소 검색
       */
      if (
        filter.location.address &&
        !filter.location.lat &&
        !filter.location.lng
      ) {
        if (!el.address.main.includes(filter.location.address)) return false;
      }

      if (filter.working_status === 'all') {
        return true;
      } else {
        if (filter.working_status === 'open') {
          return el.isWorking === true;
        } else {
          return el.isWorking === false;
        }
      }
    });
  }, [totalRepairshopList, filter]);

  const sortedRepairshopList: (RepairShop & {
    distance?: number;
    isWorking: boolean;
  })[] = useMemo(() => {
    if (!filteredRepairshopList) return [];

    /** 현재 내 위치 정보가 있을 경우 */
    if (filter.location.lat && filter.location.lng) {
      const dataWithDistance = filteredRepairshopList.map((el) => {
        return {
          ...el,
          distance: getDistance(
            {
              latitude: filter.location.lat!,
              longitude: filter.location.lng!,
            },
            {
              latitude: el.location.latitude!,
              longitude: el.location.longitude!,
            },
          ),
        };
      });
      return dataWithDistance.sort((a, b) => {
        return a.distance - b.distance;
      });
    }

    /** 그 외의 경우 이름 가나다순 정렬 */
    return filteredRepairshopList.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [filteredRepairshopList]);

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { setCurrentLocation } = useCurrentLocationStore();
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);

  const [isLocationDrawerOpen, setIsLocationDrawerOpen] = useState(false);
  const openLocationSubpage = () => {
    setIsLocationDrawerOpen(true);
  };

  const [hasScrolled, setHasScrolled] = useState(false); // 스크롤 여부 상태
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const [isRepairEmpty, setRepairEmpty] = useState(false);

  const [isOpenRepairDetail, setIsOpenRepairDetail] = useState(false);
  const [targetDetailRepairShop, setTargetDetailRepairShop] = useState<
    (RepairShop & { distance?: number; isWorking: boolean }) | null
  >(null);
  /** 서브페이지 여닫기 처리 */
  useEffect(() => {
    if (targetDetailRepairShop) {
      setIsOpenRepairDetail(true);
    }
  }, [targetDetailRepairShop]);

  const handleScroll = useCallback(() => {
    if (divRef.current) {
      const scrollTop = divRef.current.scrollTop;
      if (scrollTop > 20) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    }
  }, []);

  const updateHeaderHeight = () => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, keyword: e.target.value });
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <ChevronLeft width={32} height={32} strokeWidth={1.5} />
        </div>
      ),
      title: '공업사',
    });
    setIsBottomTabbar(false);
    return () => {
      // 물어보기 메뉴 변경시 현재 위치 저장 location  초기화
      setCurrentLocation({
        lat: 37.5461181757326,
        lng: 127.050040830902,
      });
    };
  }, []);

  useEffect(() => {
    const divElement = divRef.current;

    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    updateHeaderHeight();

    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  return (
    <>
      <div className='w-full h-full'>
        <div
          ref={headerRef}
          className={`min-h-[156px] w-full pt-[14px] pb-[12px] px-[20px] bg-white transition-all ${hasScrolled ? 'relative z-10 shadow-[0px_4px_10px_#1111111A]' : 'shadow-none'}`}
        >
          <div className='w-full relative mb-6'>
            <input
              value={filter.keyword}
              onChange={handleChangeSearch}
              type='text'
              placeholder='공업사를 검색해보세요'
              className={`w-full flex justify-start items-center transition-all placeholder:text-[#999999] bg-[#F3F6F9] py-[12px] px-4 h-[50px] ${filter.keyword && filter.keyword.length > 0 && '!pr-[80px]'} rounded-[10px] text-[15px] leading-[15px] font-[500] focus:outline-0 focus:ring-0 focus:border-none`}
            />
            {filter.keyword.length > 0 && (
              <button
                className='absolute right-12 bottom-0 top-0 my-auto'
                onClick={() => {
                  setFilter({ ...filter, keyword: '' });
                }}
              >
                <img
                  src='/assets/images/v2/close-circle.png'
                  alt='삭제 아이콘'
                  className='w-[20px] h-[20px] object-contain'
                />
              </button>
            )}

            <button className='absolute right-3.5 bottom-0 top-0 my-auto'>
              <img
                src='/assets/images/v2/search.png'
                alt='검색 아이콘'
                className='w-6 h-6 object-contain'
              />
            </button>
          </div>
          {/* 위치 기반 검색 영역 */}
          <div className='flex justify-between items-center mb-4 h-5'>
            <div
              className='flex justify-start items-center gap-[4px]'
              onClick={openLocationSubpage}
            >
              <img
                src='/assets/images/v2/gps-gray.png'
                alt='gps아이콘'
                className='w-5 object-contain'
              />
              <span className='text-[14px] leading-[14px] font-[500]'>
                {filter.location.address ?? '위치 설정'}
              </span>
            </div>
            {filter.location.lat && (
              <div className='flex justify-start items-center gap-1'>
                <img
                  src='/assets/images/v2/nearest-order-gray.png'
                  alt='정렬아이콘'
                  className='w-4 object-contain'
                />
                <span className='text-[14px] leading-[14px] font-[500]'>
                  가까운순
                </span>
              </div>
            )}
          </div>
          {/* 카테고리 선택 메뉴 */}
          {!isRepairEmpty && (
            <div className='flex flex-wrap justify-between items-center gap-3'>
              <ul className='flex justify-start items-center gap-[6px]'>
                <li
                  onClick={() =>
                    setFilter({ ...filter, working_status: 'all' })
                  }
                  className={cn(
                    'border border-transparent cursor-pointer transition-all h-[34px] inline-flex justify-center items-center py-[10px] px-[14px] rounded-[100px] text-[14px] leading-[14px] bg-[#F3F6F9]',
                    filter.working_status === 'all' &&
                      ' bg-[#E6E6F6] border-[#7273F9] text-[#7273F9] font-[600]',
                  )}
                >
                  <button>전체</button>
                </li>
                <li
                  onClick={() =>
                    setFilter({ ...filter, working_status: 'open' })
                  }
                  className={cn(
                    'border border-transparent cursor-pointer transition-all h-[34px] inline-flex justify-center items-center py-[10px] px-[14px] rounded-[100px] text-[14px] leading-[14px] bg-[#F3F6F9]',
                    filter.working_status === 'open' &&
                      ' bg-[#E6E6F6] border-[#7273F9] text-[#7273F9] font-[600]',
                  )}
                >
                  <button>영업중</button>
                </li>
                <li
                  onClick={() =>
                    setFilter({ ...filter, working_status: 'closed' })
                  }
                  className={cn(
                    'border border-transparent cursor-pointer transition-all h-[34px] inline-flex justify-center items-center py-[10px] px-[14px] rounded-[100px] text-[14px] leading-[14px] bg-[#F3F6F9]',
                    filter.working_status === 'closed' &&
                      ' bg-[#E6E6F6] border-[#7273F9] text-[#7273F9] font-[600]',
                  )}
                >
                  <button>영업마감 </button>
                </li>
              </ul>
              <div>
                {filter.towing_available ? (
                  <div
                    onClick={() => {
                      setFilter({
                        ...filter,
                        towing_available: false,
                      });
                    }}
                    className='flex justify-center items-center gap-[5px] cursor-pointer'
                  >
                    <strong className='block w-[20px] h-[20px]'>
                      <img
                        src='/assets/images/png/check.png'
                        alt='체크아이콘'
                        className='w-full object-contain'
                      />
                    </strong>
                    <span className='text-[14px] leading-[14px] font-[500]'>
                      견인 가능
                    </span>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setFilter({
                        ...filter,
                        towing_available: true,
                      });
                    }}
                    className='flex justify-center items-center gap-[5px] cursor-pointer'
                  >
                    <strong className='block w-[20px] h-[20px]'>
                      <img
                        src='/assets/images/png/unCheck.png'
                        alt='비비체크아이콘'
                        className='w-full object-contain'
                      />
                    </strong>
                    <span className='text-[14px] leading-[14px] font-[500] text-[#7D848A]'>
                      견인 가능
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className={`w-full pt-[20px] pb-[50px] px-[20px] overflow-y-auto ${isRepairEmpty ? 'bg-white' : 'bg-[#F3F6F9]'}`}
          style={{
            height: headerHeight
              ? `calc(100% - ${headerHeight}px)`
              : 'calc(100% - 156px)',
          }}
          ref={divRef}
        >
          <div className='w-full h-[100%-50px] flex flex-col justify-start items-center gap-[20px]'>
            {isLoading ? (
              <div className='w-full h-full flex justify-center items-center'>
                <CircularProgress size={40} />
              </div>
            ) : !sortedRepairshopList || sortedRepairshopList.length === 0 ? (
              <RepairEmpty />
            ) : (
              <>
                <div
                  className={cn(
                    'w-full flex flex-col rounded-[16px] p-4 bg-[#E5DFD8] gap-4',
                  )}
                >
                  <div className='flex gap-2 items-center tiny:gap-1'>
                    <img
                      src={'/assets/images/v2/chat-cargong-icon.png'}
                      alt={`chat_카공이_icon`}
                      className='w-[36px] h-[36px] object-contain'
                    />
                    <div className='text-[#222222] text-[15px] font-semibold leading-[100%] tracking-[-0.3px]'>
                      카공이
                    </div>
                    <div className='h-[16px] w-[1px] bg-[#D4CDC4] mx-0.5'></div>
                    <div className='text-[#222222] text-[16px] font-semibold leading-[100%] tracking-[-0.32px]'>
                      공업사에 요청해 보세요.
                    </div>
                  </div>
                  <div className='w-full h-[1px] bg-[#D4CDC4]'></div>
                  <div className='overflow-hidden text-[#222222] text-ellipsis whitespace-nowrap text-[15px] font-medium leading-[150%] tracking-[-0.3px]'>
                    자동차 사고 또는 고장 요청이 필요하시다면
                    <br />
                    나의 위치 기반으로 가까운 공업사에 요청이 갑니다.
                  </div>
                  <V3_Button
                    onClick={() => {
                      if (!loginMember) return navigate('/auth/login');
                      navigate('/accidentReport/init');
                    }}
                  >
                    접수하기
                  </V3_Button>
                </div>
                {sortedRepairshopList.map((el, index) => {
                  return (
                    <RepairShopCard
                      repairShop={el}
                      key={index}
                      onClick={() => {
                        setTargetDetailRepairShop(el);
                        setIsOpenRepairDetail(true);
                      }}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <SelectLocationSubpage
        isOpen={isLocationDrawerOpen}
        setIsOpen={setIsLocationDrawerOpen}
        filter={filter}
        setFilter={setFilter}
      />

      {targetDetailRepairShop && (
        <RepairDetailSubPage
          repairShop={targetDetailRepairShop}
          isOpen={isOpenRepairDetail}
          setIsOpen={setIsOpenRepairDetail}
        />
      )}
    </>
  );
};
export default RepairMainList;
