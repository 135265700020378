import V3_Button from 'components_v3/ui/Button';
import { ChevronDown } from 'lucide-react';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

export default function DealerQuoteDetail() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramState = searchParams.get('state') ?? 'request';
  const [infoSection, setInfoSection] = useState(false);
  const [carManagerSection, setCarManagerSection] = useState(false);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '견적 상세',
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div>
      <div className='py-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
        <div className='mb-6'>
          <div className='mb-4'>
            <span className='text-[#FF4747] text-[14px] leading-[14px] font-[600] inline-flex justify-start items-center gap-1 py-[5px] pl-1 pr-1.5 bg-[#FFF0F0] rounded-[6px]'>
              <img
                src='/assets/images/png/clock.png'
                alt='시계 아이콘'
                className='w-4 object-contain'
              />
              29일 32:11:14
            </span>
          </div>
          <strong className='text-[20px] leading-[32px] font-[600]'>
            견적 요청을 받았어요.
            <br />
            견적서를 전달해 주세요.
          </strong>
          <p className='mt-2.5 text-[14px] leading-[14px] font-[500] text-[#555555]'>
            주문번호 C2412310000O
          </p>
        </div>
        {paramState === 'request' ? (
          <V3_Button
            theme='primary'
            onClick={() => navigate('/dealer/quote/write')}
          >
            견적서 작성하기
          </V3_Button>
        ) : (
          <V3_Button
            theme='gray'
            onClick={() => navigate('/dealer/quote/written')}
          >
            작성한 견적서 보기
          </V3_Button>
        )}
      </div>
      <div className='pt-[15px] px-5 border-b-[8px] border-[#F3F6F9]'>
        <div
          className='flex justify-between items-center gap-4 pb-[15px] cursor-pointer'
          onClick={() => setInfoSection(!infoSection)}
        >
          <span className='text-[18px] leading-[18px] font-[600]'>
            요청 정보
          </span>
          <button>
            <ChevronDown
              width={30}
              height={30}
              color='#666666'
              className={cn(
                'transition-all',
                infoSection ? 'rotate-180' : 'rotate-0',
              )}
            />
          </button>
        </div>
        {infoSection && (
          <div className='pb-[30px] pt-5'>
            <div className='flex flex-col justify-start items-start gap-[30px]'>
              <div className='w-full flex justify-start items-center gap-4'>
                <div className='w-[140px] rounded-[10px] aspect-[140/110] bg-[#F3F6F9] pb-4 pt-[26px] px-2.5'>
                  <img
                    src='/assets/images/png/ex-car.png'
                    alt='예시 차량 이미지'
                    className='h-full object-contain'
                  />
                </div>
                <div className='flex flex-col justify-start items-start gap-1'>
                  <span className='inline-block mb-0.5 text-[14px] leading-[14px] font-[500] text-[#555]'>
                    할부
                  </span>
                  <strong className='text-[16px] leading-[25.6px] font-[600] break-keep'>
                    기아 GLE-클래스 24년식
                  </strong>
                  <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555] break-keep'>
                    가솔린 2.5 터보 2WD 기본형
                  </p>
                </div>
              </div>
              <div className='mb-[30px]'>
                <div className='flex justify-between items-center gap-3 mb-6'>
                  <span className='text-[15px] leading-[15px] font-[500] text-[#555]'>
                    총 예상 금액
                  </span>
                  <div className='flex justify-center items-center gap-1'>
                    <strong>88,050,000원</strong>
                    <img
                      src='/assets/images/png/info_lightgray.png'
                      alt='정보 아이콘'
                      className='w-5 object-contain'
                    />
                  </div>
                </div>
                <div className='bg-[#F3F6F9] rounded-[10px] px-4 py-[15px] text-[14px] leading-[21px] font-[400] text-[#555555]'>
                  자동차 할인 금액에 따라 금액은 변동될 수 있습니다.
                </div>
              </div>
            </div>
            <div>
              <span className='text-[16px] leading-[16px] font-[600] inline-block mb-6'>
                구매 방법
              </span>
              <div className='p-4 bg-[#F3F6F9] rounded-[10px]'>
                <ul className='w-full flex flex-col justify-start items-start gap-[14px]'>
                  <li className='w-full flex justify-between items-center gap-3'>
                    <span className='text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                      유형
                    </span>
                    <strong className='text-[15px] leading-[22.5px] font-[500] text-[#222]'>
                      할부
                    </strong>
                  </li>
                  <li className='w-full flex justify-between items-center gap-3'>
                    <span className='text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                      구입주체
                    </span>
                    <strong className='text-[15px] leading-[22.5px] font-[500] text-[#222]'>
                      개인
                    </strong>
                  </li>
                  <li className='w-full flex justify-between items-center gap-3'>
                    <span className='text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                      계약기간
                    </span>
                    <strong className='text-[15px] leading-[22.5px] font-[500] text-[#222]'>
                      12개월
                    </strong>
                  </li>
                  <li className='w-full flex justify-between items-center gap-3'>
                    <span className='text-[15px] leading-[15px] text-[#7D848A] font-[500] break-keep'>
                      선수금 (인도금)
                    </span>
                    <strong className='text-[15px] leading-[22.5px] font-[500] text-[#222] break-keep text-right'>
                      1,000,000원 (등록비 별도)
                    </strong>
                  </li>
                  <li className='w-full flex justify-between items-center gap-3'>
                    <span className='text-[15px] leading-[15px] text-[#7D848A] font-[500] break-keep'>
                      할부원금
                    </span>
                    <strong className='text-[15px] leading-[22.5px] font-[500] text-[#222] break-keep text-right'>
                      생략
                    </strong>
                  </li>
                  <li className='w-full flex justify-between items-center gap-3'>
                    <span className='text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                      구입시기
                    </span>
                    <strong className='text-[15px] leading-[22.5px] font-[500] text-[#222]'>
                      1개월 이내
                    </strong>
                  </li>
                  <li className='w-full flex justify-between items-center gap-3'>
                    <span className='text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                      거주지역
                    </span>
                    <strong className='text-[15px] leading-[22.5px] font-[500] text-[#222]'>
                      인천시 중구
                    </strong>
                  </li>
                  <li className='w-full flex justify-between items-center gap-3'>
                    <span className='text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                      인수지역
                    </span>
                    <strong className='text-[15px] leading-[22.5px] font-[500] text-[#222]'>
                      서울시 중랑구
                    </strong>
                  </li>
                  <li className='w-full flex justify-between items-center gap-3'>
                    <span className='text-[15px] leading-[15px] text-[#7D848A] font-[500]'>
                      면세조건
                    </span>
                    <strong className='text-[15px] leading-[22.5px] font-[500] text-[#222]'>
                      일반인 (해당없음)
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className=''>
        <div
          className='flex justify-between items-center gap-4 py-[15px] px-5 cursor-pointer'
          onClick={() => setCarManagerSection(!carManagerSection)}
        >
          <span className='text-[18px] leading-[18px] font-[600]'>
            참여 카 매니저
            <strong className='inline-block ml-2 text-[#7273F9] text-[18px] leading-[18px] font-[600]'>
              3명
            </strong>
          </span>
          <button>
            <ChevronDown
              width={30}
              height={30}
              color='#666666'
              className={cn(
                'transition-all',
                carManagerSection ? 'rotate-180' : 'rotate-0',
              )}
            />
          </button>
        </div>
        {carManagerSection && (
          <div className='w-full bg-[#F3F6F9] p-5 pb-[50px]'>
            <ul className='flex flex-col justify-start items-start gap-5'>
              <li className='w-full py-5 px-4 bg-white rounded-[16px] flex justify-start items-center gap-4 shadow-[0px_6px_20px_#0000000D]'>
                <div className='w-[56px] h-[56px] rounded-full overflow-hidden'>
                  <img
                    src='/assets/images/png/review_repairExImg.png'
                    alt='차량 예시 이미지'
                    className='object-cover'
                  />
                </div>
                <div>
                  <strong className='text-[16px] leading-[16px] font-[600]'>
                    낭만적인 말미잘
                  </strong>
                  <div className='flex justify-start items-center gap-1 text-[14px] leading-[14px] font-[500] text-[#555] mt-2'>
                    <span className='flex justify-start items-center gap-0.5'>
                      <img
                        src='/assets/images/png/star.png'
                        alt='별 아이콘'
                        className='w-3 object-contain'
                      />
                      4.8
                    </span>
                    <span className='w-[3px] h-[3px] rounded-full bg-[#D5DADF]'></span>
                    <span>신차 매니저</span>
                  </div>
                </div>
              </li>
              <li className='w-full py-5 px-4 bg-white rounded-[16px] flex justify-start items-center gap-4 shadow-[0px_6px_20px_#0000000D]'>
                <div className='w-[56px] h-[56px] rounded-full overflow-hidden'>
                  <img
                    src='/assets/images/png/review_repairExImg.png'
                    alt='차량 예시 이미지'
                    className='object-cover'
                  />
                </div>
                <div>
                  <strong className='text-[16px] leading-[16px] font-[600]'>
                    낭만적인 말미잘
                  </strong>
                  <div className='flex justify-start items-center gap-1 text-[14px] leading-[14px] font-[500] text-[#555] mt-2'>
                    <span className='flex justify-start items-center gap-0.5'>
                      <img
                        src='/assets/images/png/star.png'
                        alt='별 아이콘'
                        className='w-3 object-contain'
                      />
                      4.8
                    </span>
                    <span className='w-[3px] h-[3px] rounded-full bg-[#D5DADF]'></span>
                    <span>신차 매니저</span>
                  </div>
                </div>
              </li>
              <li className='w-full py-5 px-4 bg-white rounded-[16px] flex justify-start items-center gap-4 shadow-[0px_6px_20px_#0000000D]'>
                <div className='w-[56px] h-[56px] rounded-full overflow-hidden'>
                  <img
                    src='/assets/images/png/review_repairExImg.png'
                    alt='차량 예시 이미지'
                    className='object-cover'
                  />
                </div>
                <div>
                  <strong className='text-[16px] leading-[16px] font-[600]'>
                    낭만적인 말미잘
                  </strong>
                  <div className='flex justify-start items-center gap-1 text-[14px] leading-[14px] font-[500] text-[#555] mt-2'>
                    <span className='flex justify-start items-center gap-0.5'>
                      <img
                        src='/assets/images/png/star.png'
                        alt='별 아이콘'
                        className='w-3 object-contain'
                      />
                      4.8
                    </span>
                    <span className='w-[3px] h-[3px] rounded-full bg-[#D5DADF]'></span>
                    <span>신차 매니저</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
