import V3_Button from 'components_v3/ui/Button';
import { X } from 'lucide-react';
import { useLayoutEffect } from 'react';
import useSystemStore from 'store/useSystemStore';

export default function DealerWrittenQuotePage() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '견적서',
      leftElement: undefined,
      rightElement: (
        <button>
          <X width={28} height={28} color='#222' />
        </button>
      ),
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='px-5 pt-[50px] pb-2.5 min-h-[calc(100dvh-60px)] flex flex-col justify-between items-start gap-[50px]'>
      <div className='w-full'>
        <div className='text-[15px] leading-[15px] font-[400] text-[#222] flex justify-between items-center pb-5 border-b-[2px] border-[#222222]'>
          <span>예상 구매 가격</span>
          <div className='text-[#7D848A] text-[15px] leading-[15px] font-[500]'>
            <span className='text-[18px] leading-[18px] font-[500] text-[#222] mr-1.5'>
              20,000,000
            </span>
            원
          </div>
        </div>
        <div className='mt-[30px] w-full flex flex-col justify-start items-start gap-6'>
          <div className='w-full'>
            <span className='text-[15px] leading-[15px] font-[400] inline-block mb-3'>
              전달사항
            </span>
            <div className='py-3 px-4 bg-[#F3F6F9] rounded-[8px] text-[16px] leading-[25.6px] font-[400]'>
              최고의 견적을 전달드리도록 노력하겠습니다. 최고의 견적을
              전달드리도록 노력하겠습니다. 최고의 견적을 전달드리도록
              노력하겠습니다. 최고의 견적을 전달드리도록 노력하겠습니다. 최고의
              견적을 전달드리도록 노력하겠습니다. 최고의 견적을 전달드리도록
              노력하겠습니다. 최고의 견적을 전달드리도록 노력하겠습니다. 최고의
              견적을 전달드리도록 노력하겠습니다. 최고의 견적을 전달드리도록
              노력하겠습니다. 최고의 견적을 전달드리도록 노력하겠습니다.
            </div>
          </div>
          <div className='w-full'>
            <span className='text-[15px] leading-[15px] font-[400] inline-block mb-3'>
              견적서
            </span>
            <div className='flex justify-start items-center p-2.5 gap-2 bg-[#F3F6F9] rounded-[8px]'>
              <img
                src='/assets/images/png/clip.png'
                alt='클립 아이콘'
                className='w-6 object-contain'
              />
              <span className='text-[14px] leading-[14px] font-[500] text-[#555555]'>
                참고이미지1.jpg
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center items-center gap-[7px]'>
        <V3_Button>삭제</V3_Button>
        <V3_Button>수정</V3_Button>
      </div>
    </div>
  );
}
