import { cn } from 'utils';
import useDeviceStore from 'store/useDeviceStore';
import { InsuranceCompany } from '@carsayo/types';

export default function InsuranceBanner({
  className,
  data,
}: {
  className?: string;
  data: InsuranceCompany;
}) {
  const { myDevice } = useDeviceStore();
  return (
    <div
      className={cn(
        'flex justify-center py-[23px] px-4 rounded-t-[10px]',
        className,
      )}
      style={{ backgroundColor: data.theme_bgcolor }}
    >
      <div className='flex items-center justify-between w-full'>
        <div className='flex flex-col justify-start gap-3'>
          <span
            className={`text-[14px] leading-[14px] tracking-[-0.02em] font-[300] ${(data.name as string).includes('한화손해보험') && (myDevice === 'browserM' || myDevice === 'ios') ? 'font-HanwhaM' : (data.name as string).includes('한화손해보험') && 'font-HanwhaW'}`}
          >
            {data.name}
          </span>
          <span
            className={`text-[18px] tracking-[-0.02em] font-[600] break-keep ${(data.name as string).includes('한화손해보험') && (myDevice === 'browserM' || myDevice === 'ios') ? 'font-HanwhaM' : (data.name as string).includes('한화손해보험') && 'font-HanwhaW'}`}
          >
            {data.direct.title}
          </span>
        </div>
        <img className='w-12 h-12' src={data.logo_url} alt='보험사 로고' />
      </div>
    </div>
  );
}
