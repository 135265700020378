import { FileInfo } from '@carsayo/types';
import CarsayoInput from 'components/common/CarsayoInput';
import ImageUpload from 'components/common/v3/fileUploadInput';
import V3_Button from 'components_v3/ui/Button';
import TextArea from 'components_v3/ui/TextArea';
import { X } from 'lucide-react';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

export default function DealerQuoteWritePage() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();
  const [textContent, setTextContent] = useState<string>('');

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '견적서 작성',
      leftElement: undefined,
      rightElement: (
        <button onClick={() => navigate(-1)}>
          <X width={28} height={28} color='#222' />
        </button>
      ),
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='px-5 pt-[30px] pb-2.5 min-h-[calc(100dvh-60px)] flex flex-col justify-between items-start gap-[50px]'>
      <div className='w-full'>
        <div className='text-[18px] leading-[28.8px] font-[500] text-[#222] mb-[50px]'>
          작성한 내용은 <br />
          바로 고객님께 전달됩니다.
        </div>
        <div className='w-full flex flex-col justify-start items-start gap-6'>
          <div className='w-full'>
            <span className='text-[15px] leading-[15px] font-[400] inline-block mb-3'>
              예상 구매 가격
            </span>
            <CarsayoInput />
          </div>
          <div className='w-full'>
            <span className='text-[15px] leading-[15px] font-[400] inline-block mb-3'>
              전달사항
            </span>
            <div className='pb-[54px] relative border border-[#E5E5EC] rounded-[8px]'>
              <TextArea
                onChange={(e) => setTextContent(e.target.value)}
                className='resize-none w-full min-h-[200px] text-[16px] leading-[25.6px] font-[400] placeholder:text-[#999999] border-none'
                placeholder='내용을 입력해 주세요.'
              />
              <span className='absolute right-4 bottom-3 text-[#999999] text-[14px] leading-[22.4px] font-[500]'>
                {textContent.length ?? 0}/1,000
              </span>
            </div>
          </div>
          <div className='w-full'>
            <span className='text-[15px] leading-[15px] font-[400] inline-block mb-3'>
              견적서
            </span>
            <ImageUpload
              id={''}
              placeholder='파일을 선택해 주세요.'
              onUpload={function (item: FileInfo, id?: string): void {
                throw new Error('Function not implemented.');
              }}
            />
          </div>
          {/* 안내사항 영역 */}
          <div className='flex flex-col p-4 pb-[20px] bg-[#F3F6F9] tracking-[-0.025em] w-full rounded-[10px] mt-1'>
            <span className='text-[15px] font-[500] leading-[22.5px] tracking-[-0.02em] pb-4 mb-5 border-b border-[#E5EAEE]'>
              확인해 주세요
            </span>

            <ul className='text-[#555555] break-all font-[400] text-[14px] leading-[21px]'>
              <li
                className={cn(
                  'relative pl-4 break-keep',
                  'after:content-["•"] after:absolute after:left-1 after:top-0',
                )}
              >
                고객님께 제시한 견적 내용과 실제 내용이 다를 경우 불이익을 받을
                수 있습니다.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center items-center gap-[7px]'>
        <V3_Button>닫기</V3_Button>
        <V3_Button theme='primary'>견적서 전달</V3_Button>
      </div>
    </div>
  );
}
